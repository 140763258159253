import { LogLevel } from '../types/logging';

const LOG_ICONS = {
  AUTH: '👤',
  NOTIFICATION: '🔔',
  WEBSOCKET: '🔌',
  CALENDAR: '📅',
  GOOGLE: '📆',
  MICROSOFT: '💠',
  CACHE: '📦',
  SYSTEM: '⚙️',
  SUCCESS: '✅',
  ERROR: '❌',
  WARNING: '⚠️',
  INFO: '📝',
  DEBUG: '🔍',
  CLEANUP: '🧹',
} as const;

type LogCategory = keyof typeof LOG_ICONS;

interface LoggerConfig {
  showEmoji: boolean;
  minLevel: LogLevel;
  enabledCategories: Set<LogCategory>;
}

// Default configuration based on environment
const defaultConfig: LoggerConfig = {
  showEmoji: import.meta.env.VITE_SHOW_LOG_EMOJI !== 'false',
  minLevel: (import.meta.env.VITE_LOG_LEVEL as LogLevel) || 'info',
  enabledCategories: new Set(
    (import.meta.env.VITE_ENABLED_LOG_CATEGORIES || '*')
      .split(',')
      .map(cat => cat.trim().toUpperCase() as LogCategory)
  )
};

const LOG_LEVELS: Record<LogLevel, number> = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3
};

class Logger {
  private config: LoggerConfig;
  private category: LogCategory;

  constructor(category: LogCategory, config: Partial<LoggerConfig> = {}) {
    this.category = category;
    this.config = { ...defaultConfig, ...config };
  }

  private shouldLog(level: LogLevel): boolean {
    const isLevelEnabled = LOG_LEVELS[level] >= LOG_LEVELS[this.config.minLevel];
    const isCategoryEnabled = 
      this.config.enabledCategories.has(this.category) || 
      this.config.enabledCategories.has('*' as LogCategory);
    return isLevelEnabled && isCategoryEnabled;
  }

  private formatMessage(level: LogLevel, message: string): string {
    const icon = this.config.showEmoji ? LOG_ICONS[this.category] : '';
    return `${icon} [${this.category}] ${message}`;
  }

  debug(message: string, ...args: any[]): void {
    if (this.shouldLog('debug')) {
      console.debug(this.formatMessage('debug', message), ...args);
    }
  }

  info(message: string, ...args: any[]): void {
    if (this.shouldLog('info')) {
      console.info(this.formatMessage('info', message), ...args);
    }
  }

  warn(message: string, ...args: any[]): void {
    if (this.shouldLog('warn')) {
      console.warn(this.formatMessage('warn', message), ...args);
    }
  }

  error(message: string, ...args: any[]): void {
    if (this.shouldLog('error')) {
      console.error(this.formatMessage('error', message), ...args);
    }
  }

  // Allow runtime configuration updates
  updateConfig(config: Partial<LoggerConfig>): void {
    this.config = { ...this.config, ...config };
  }
}

// Export pre-configured loggers for common categories
export const logger = {
  auth: new Logger('AUTH'),
  notification: new Logger('NOTIFICATION'),
  websocket: new Logger('WEBSOCKET'),
  calendar: new Logger('CALENDAR'),
  google: new Logger('GOOGLE'),
  microsoft: new Logger('MICROSOFT'),
  cache: new Logger('CACHE'),
  system: new Logger('SYSTEM'),
};

// Export the Logger class for custom categories
export { Logger, type LogCategory, type LoggerConfig };
