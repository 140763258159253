import { useCallback } from 'react';
import axios from 'axios';
import { subDays, addDays, startOfDay, endOfDay, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';
import { logger } from '../../../utils/logger';

const googleColorConstants = {
    undefined: '#039be5',
    1: '#7986cb',
    2: '#33b679',
    3: '#8e24aa',
    4: '#e67c73',
    5: '#f6c026',
    6: '#f5511d',
    7: '#039be5',
    8: '#616161',
    9: '#3f51b5',
    10: '#0b8043',
    11: '#d60000',
};

export const useGoogleCalendarEvents = (customer) => {
    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';
    const [token] = useAtom(tokenAtom);

    const fetchGoogleCalendars = useCallback(async (accessToken) => {
        try {
            logger.google.info('Fetching calendars');
            const { data } = await axios.get('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            logger.google.debug(`Found ${data.items?.length || 0} calendars`);
            return data;  // Return the full data object to maintain compatibility
        } catch (error) {
            if (error.response?.status === 401) {
                logger.google.warn('Token expired - needs refresh');
                throw error; // Propagate 401 errors up
            }
            logger.google.error('Failed to fetch calendars', error);
            return { items: [] };  // Return compatible structure
        }
    }, []);

    const fetchGoogleEvents = useCallback(
        async (currentCalendarList) => {
            if (!currentCalendarList?.items?.length) {
                logger.google.info('No Google calendars to fetch events from');
                return [];
            }

            logger.google.info(`Fetching events from ${currentCalendarList.items.length} Google calendars`);
            logger.google.debug(`Found ${currentCalendarList.items.length} calendars`);
            const defaultTimeZone = customer.defaultTimeZone;
            const now = new Date();
            const minDate = startOfDay(subDays(now, 7));
            const maxDate = endOfDay(addDays(now, 90));

            logger.google.debug('Date range:', {
                defaultTimeZone,
                minDate: minDate.toISOString(),
                maxDate: maxDate.toISOString(),
            });

            const googleCalendars = currentCalendarList.items.filter((calendar) => {
                logger.google.debug('Filtering calendar:', {
                    id: calendar.id,
                    accountType: calendar.account?.type,
                    dirty: calendar.account?.dirty,
                    isHoliday: calendar.id.indexOf('#holiday@group.v.calendar.google.com') !== -1,
                });

                const account = customer.accounts.find((account) =>
                    account.calendars.some((accCal) => accCal.id === calendar.id)
                );
                const accountCalendar = account ? account.calendars.find((accCal) => accCal.id === calendar.id) : null;
                const isUsing = accountCalendar ? accountCalendar.using : calendar.using;

                logger.google.debug('Calendar details:', {
                    id: calendar.id,
                    hasAccount: !!account,
                    hasAccountCalendar: !!accountCalendar,
                    isUsing,
                });

                if (calendar.account.type !== 'google') {
                    return false;
                }
                if (calendar.account.dirty) {
                    return false;
                }
                if (calendar.id.indexOf('#holiday@group.v.calendar.google.com') !== -1) {
                    return false;
                }
                // Uncomment if you want to filter by usage status
                // if (!isUsing) {
                //     return false;
                // }
                return true;
            });

            logger.google.debug('Filtered Google calendars:', googleCalendars);

            const allEvents = await Promise.all(
                googleCalendars.map(async (calendar) => {
                    logger.google.debug(`Fetching events for calendar: ${calendar.id}`);
                    try {
                        const response = await axios.get(
                            `https://www.googleapis.com/calendar/v3/calendars/${calendar.id}/events`,
                            {
                                params: {
                                    timeMin: formatInTimeZone(minDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
                                    timeMax: formatInTimeZone(maxDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
                                    maxResults: 1000,
                                    singleEvents: true,
                                    orderBy: 'startTime',
                                    fields:
                                        'items(id,summary,description,location,creator,organizer,start,end,' +
                                        'attendees(displayName,email,responseStatus,optional,organizer,self),recurringEventId,recurrence,sequence,colorId,visibility,' +
                                        'iCalUID,attachments,conferenceData,extendedProperties,hangoutLink,' +
                                        'guestsCanModify,guestsCanInviteOthers,guestsCanSeeOtherGuests,' +
                                        'privateCopy,locked,source,status,created,updated,reminders,eventType,' +
                                        'transparency,htmlLink)',
                                },
                                headers: {
                                    Authorization: `Bearer ${calendar.account.accessToken}`,
                                    'Content-Type': 'application/json',
                                },
                            }
                        );
                        logger.google.info(`Successfully fetched events for calendar ${calendar.id}:`, {
                            eventCount: response.data.items.length,
                        });

                        // logger.google.debug('Google Calendar Event Attendees:', response.data.items);
                        return response.data.items.map((event) => ({
                            id: event.id,
                            calendarId: calendar.id,
                            htmlLink: event.htmlLink,
                            title: event.summary,
                            start: event.start.dateTime || event.start.date,
                            end: event.end.dateTime || event.end.date,
                            allDay: !event.start.dateTime,
                            color: calendar.backgroundColor || googleColorConstants[event.colorId],
                            accessToken: calendar.account.accessToken,
                            status: event.transparency === 'transparent' ? 'free' : 'busy',
                            textColor: 'white',
                            serviceType: 'google',
                            // Additional useful fields
                            description: event.description,
                            location: event.location,
                            creator: event.creator,
                            organizer: event.organizer,
                            attendees: event.attendees
                                ? event.attendees.map((attendee) => {
                                      // logger.google.debug('Raw attendee data:', {
                                      //     email: attendee.email,
                                      //     displayName: attendee.displayName,
                                      //     responseStatus: attendee.responseStatus,
                                      //     optional: attendee.optional,
                                      //     organizer: attendee.organizer,
                                      //     self: attendee.self,
                                      // });

                                      // Extract email parts for better name fallback
                                      const emailParts = attendee.email.split('@');
                                      const emailUsername = emailParts[0];
                                      const emailDomain = emailParts[1];

                                      // Try multiple sources for the name
                                      let name = attendee.displayName;
                                      if (!name && attendee.email === event.organizer?.email) {
                                          name = event.organizer.displayName;
                                      }
                                      if (!name) {
                                          // Convert email username to a more readable format
                                          name = emailUsername
                                              .replace(/[._]/g, ' ') // Replace dots and underscores with spaces
                                              .replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalize first letter of each word
                                      }

                                      const attendeeInfo = {
                                          email: attendee.email,
                                          name: name,
                                          responseStatus: attendee.responseStatus || 'needsAction',
                                          optional: attendee.optional || false,
                                          organizer:
                                              attendee.organizer || attendee.email === event.organizer?.email || false,
                                          self: attendee.self || false,
                                      };

                                      // logger.google.debug('Processed attendee info:', attendeeInfo);
                                      return attendeeInfo;
                                  })
                                : [],
                            recurringEventId: event.recurringEventId,
                            recurrence: event.recurrence,
                            sequence: event.sequence, // For tracking updates to recurring events
                            colorId: event.colorId,
                            visibility: event.visibility, // 'default', 'public', 'private', 'confidential'
                            iCalUID: event.iCalUID,
                            attachments: event.attachments,
                            conferenceData: event.conferenceData, // For Google Meet links
                            extendedProperties: event.extendedProperties,
                            hangoutLink: event.hangoutLink,
                            guestsCanModify: event.guestsCanModify,
                            guestsCanInviteOthers: event.guestsCanInviteOthers,
                            guestsCanSeeOtherGuests: event.guestsCanSeeOtherGuests,
                            privateCopy: event.privateCopy,
                            locked: event.locked,
                            source: event.source, // Where the event came from
                            status: event.status, // 'confirmed', 'tentative', 'cancelled'
                            created: event.created,
                            updated: event.updated,
                            reminders: event.reminders,
                            eventType: event.eventType, // 'default', 'outOfOffice', 'focusTime'
                        }));
                    } catch (error) {
                        logger.google.error(`Failed to fetch events for calendar ${calendar.id}:`, {
                            status: error.response?.status,
                            data: error.response?.data,
                            message: error.message,
                        });
                        return [];
                    }
                })
            );

            const flattenedEvents = allEvents.flat();
            logger.google.info(`Google Events: ${flattenedEvents.length} events from ${googleCalendars.length} calendars`);
            return flattenedEvents;
        },
        [customer, googleColorConstants]
    );

    const updateEventStatus = useCallback(async (eventId, calendarId, accessToken, newStatus) => {
        try {
            const response = await axios.patch(
                `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
                {
                    transparency: newStatus === 'tentative' ? 'transparent' : 'opaque',
                    status: newStatus === 'tentative' ? 'tentative' : 'confirmed'
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            logger.google.error('Error updating Google event status:', error);
            throw error;
        }
    }, []);

    const updateGoogleEvent = useCallback(async (calendarId, eventId, accessToken, updatedEvent) => {
        logger.google.info(`Updating event in calendar ${calendarId}`);
        logger.google.debug('Updated event data:', updatedEvent);
        try {
            const response = await axios.patch(
                `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
                updatedEvent,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            logger.google.info(`Successfully updated event: ${response.data.id}`);
            return response.data;
        } catch (error) {
            logger.google.error('Error updating Google event:', error.response?.data || error.message);
            throw error;
        }
    }, []);

    const handleGoogleAuth = useCallback(
        async (authCode) => {
            logger.google.info('Sending google auth code to ' + baseUrl + 'oauth/googleauth');

            try {
                const response = await fetch(baseUrl + 'oauth/googleauth', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(authCode),
                });

                logger.google.info('Server responded with status', response.status);

                const responseData = await response.text();
                logger.google.info('Server responded with body', responseData);

                let parsedData;
                try {
                    parsedData = JSON.parse(responseData);
                } catch (e) {
                    logger.google.error('Error parsing JSON response:', e);
                    parsedData = null;
                }

                if (!response.ok) {
                    return {
                        ok: false,
                        status: response.status,
                        data: parsedData || { message: 'An unexpected error occurred' },
                    };
                }

                logger.google.info('Google auth response:', parsedData);
                return {
                    ok: true,
                    status: response.status,
                    data: parsedData,
                };
            } catch (err) {
                logger.google.error('Error during Google authentication:', err);
                return {
                    ok: false,
                    status: 500,
                    data: { message: 'An unexpected error occurred during authentication' },
                };
            }
        },
        [baseUrl, token]
    );

    return {
        fetchGoogleCalendars,
        fetchGoogleEvents,
        updateEventStatus,
        updateGoogleEvent,
        handleGoogleAuth,
    };
};

export { googleColorConstants };
