import React, { useCallback, useState, useMemo } from 'react';
import { format, isToday, isPast } from 'date-fns';
import { Dialog } from '@mui/material';
import { PriorityPanel } from '../tasks/PriorityPanel';
import { DayRow } from './DayRow';
import { motion, AnimatePresence } from 'framer-motion';
import { useTasks } from '../../providers/TasksProvider';
import { useSettings } from '../../atoms/SettingsAtom';
import { useNavigate } from 'react-router-dom';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { useDeviceType } from '../../hooks/useDeviceType';
import InlineLoading from '../InlineLoading';

export const Briefing = ({ selectedCollectionObj, isPlanner, isCalendarView }) => {
    const {
        tasks = [],
        filteredTasks = [],
        filteredPrettyEvents = {},
        filteredPrettyEventsSorted = [],
        selectedFilter,
        selectedCollection,
        isLoading: isTasksLoading,
    } = useTasks();

    const { settings, toggleSetting } = useSettings();
    const navigate = useNavigate();
    const { openTaskModal } = useTaskModal();
    const { isMobile, smallCalendar } = useDeviceType();
    const [showPriorityPanel, setShowPriorityPanel] = useState(false);
    const [priorityTask, setPriorityTask] = useState(null);

    const openPriorityPanel = useCallback((event, task) => {
        event.stopPropagation();
        setPriorityTask(task);
        setShowPriorityPanel(true);
    }, []);

    const tasksToDisplay = useMemo(() => {
        if (settings.showFuture) {
            return tasks;
        }
        return filteredTasks;
    }, [isPlanner, settings.showFuture, tasks, filteredTasks]);

    // get uncompleted tasks
    const uncompletedTasks = tasks ? tasks.filter((task) => !task.isCompleted) : [];

    const hasRelevantTasks = tasks
        ? tasksToDisplay.some((task) => {
            // Use the calendar event date if available, otherwise fall back to startDate
            const taskDate = task.googleEvent?.googleStart?.dateTime
                ? new Date(task.googleEvent.googleStart.dateTime)
                : new Date(task.startDate);

            return isToday(taskDate) || (isPast(taskDate) && !task.isCompleted);
        })
        : false;

    const shouldShowTasks = settings.showFuture || hasRelevantTasks;

    const hasNoTasks = tasksToDisplay.length === 0 && !hasRelevantTasks;

    let content;
    if (hasNoTasks && uncompletedTasks.length === 0) {
        content = (
            <div className="mt-10 text-center">
                <p>No tasks available. Please add a new task to get started.</p>
                <button onClick={() => openTaskModal()} className="px-4 py-2 mt-4 text-white bg-green-500 rounded">
                    Add New Task
                </button>
            </div>
        );
    } else if (shouldShowTasks && hasRelevantTasks) {
        content = (
            <AnimatePresence>
                {filteredPrettyEventsSorted.map((day) => (
                    <motion.div
                        key={day}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="grid grid-cols-1 ml-0 w-full text-lg border-t-2 border-solid divide-y-2 text-bold font-roboto"
                    >
                        <motion.p className="text-2xl font-bold text-blue-950 leading-tight tracking-tight shadow-md">
                            {format(new Date(day), 'd EEE MMM yyyy')}
                        </motion.p>

                        <DayRow
                            filteredPrettyEvents={filteredPrettyEvents}
                            isPlanner={isPlanner}
                            isCalendarView={false}
                            tasks={tasksToDisplay}
                            day={day}
                            openPriorityPanel={openPriorityPanel}
                            selectedCollectionObj={selectedCollectionObj}
                            selectedFilter={selectedFilter}
                            selectedCollection={selectedCollection}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        );
    } else {
        content = (
            <div className="mt-10 text-center">
                <p>
                    No tasks for today. Please toggle the "Show Future" setting or go to the{' '}
                    <a href="/planner?filter=TOTAL_TASKS" className="text-blue-500 underline">
                        Planner
                    </a>{' '}
                    and click "All Tasks" in the overview.
                </p>
                <button
                    onClick={() => {
                        toggleSetting('showFuture');
                    }}
                    className="px-4 py-2 mt-4 text-white bg-blue-500 rounded"
                >
                    Show Future Tasks
                </button>
                <button
                    onClick={() => navigate('/planner?filter=TOTAL_TASKS')}
                    className="px-4 py-2 mt-4 ml-2 text-white bg-orange-500 rounded"
                >
                    Go to Planner
                </button>
                <button onClick={() => openTaskModal()} className="px-4 py-2 mt-4 ml-2 text-white bg-green-500 rounded">
                    Add New Task
                </button>
            </div>
        );
    }

    if (isTasksLoading) {
        return (
            <div className="w-full h-full">
                <div className="p-4 pb-0">
                    <h2 className="text-2xl font-bold mb-4 text-blue-950">Today's Briefing</h2>
                    <div className="space-y-6">
                        {[1, 2, 3].map((n) => (
                            <div key={n} className="p-4 rounded-lg bg-white shadow-sm animate-pulse">
                                <div className="space-y-3">
                                    <div className="flex items-center space-x-4">
                                        <div className="h-6 w-6 bg-gray-200 rounded-full"></div>
                                        <div className="flex-1">
                                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                        </div>
                                    </div>
                                    <div className="pl-10">
                                        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="h-full">
            <div className="p-4 pb-0">
                <h2 className="text-2xl font-bold mb-4 text-blue-950">Today's Briefing</h2>
                {content}
            </div>
            <Dialog 
                open={showPriorityPanel} 
                onClose={() => setShowPriorityPanel(false)}
                style={{ zIndex: 10000 }}
            >
                <PriorityPanel task={priorityTask} notifyClose={() => setShowPriorityPanel(false)} />
            </Dialog>
        </div>
    );
};

export default Briefing;
