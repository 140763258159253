import React, { useEffect, useCallback, useState, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import { useAtom } from 'jotai';
import { tokenAtom, isTokenExpiredAtom } from '../atoms/tokenAtoms';
import { socketClientAtom } from '../atoms/webSocketAtom'; 
import { useQueryClient } from 'react-query';
import { isReschedulingAtom } from '../atoms/isReschedulingAtom';
import { useCalendarEvents } from '../contexts/CalendarEventsContext';
import { useCustomer } from '../providers/CustomerProvider';
import { useCurrentPicture } from '../contexts/CurrentPictureContext';
import { useNavigate } from 'react-router-dom';

export const WebSocketConnector = ({ customer, refetchCustomer }) => {
    const [sessionId] = React.useState(uuid());
    const { enqueueSnackbar } = useSnackbar();
    const [socket, setSocket] = useAtom(socketClientAtom);
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);
    const isConnectingRef = useRef(false);
    const mountCountRef = useRef(0);
    const hasActiveConnectionRef = useRef(false);
    const socketRef = useRef(null);
    const messageIdsRef = useRef(new Set());
    const MESSAGE_HISTORY_LIMIT = 1000;
    const RECONNECT_DELAY = 2000; // 2 seconds
    const MAX_RECONNECT_ATTEMPTS = 5;

    const { refetchCalendarEvents } = useCalendarEvents();
    const { updateCurrentPicture } = useCurrentPicture();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const reconnectAttemptRef = useRef(0);
    const reconnectTimeoutRef = useRef(null);
    const lastMessageTimestampRef = useRef({});

    const [isRescheduling, setIsRescheduling] = useAtom(isReschedulingAtom);

    // Clean up old message IDs periodically
    useEffect(() => {
        const interval = setInterval(() => {
            if (messageIdsRef.current.size > MESSAGE_HISTORY_LIMIT) {
                messageIdsRef.current = new Set(
                    Array.from(messageIdsRef.current).slice(-MESSAGE_HISTORY_LIMIT/2)
                );
            }

            // Clean up old message timestamps (older than 5 minutes)
            const now = Date.now();
            const TIMESTAMP_TTL = 5 * 60 * 1000; // 5 minutes
            Object.entries(lastMessageTimestampRef.current).forEach(([key, timestamp]) => {
                if (now - timestamp > TIMESTAMP_TTL) {
                    delete lastMessageTimestampRef.current[key];
                }
            });
        }, 60000); // Clean up every minute

        return () => clearInterval(interval);
    }, []);

    const handleMessage = useCallback((event) => {
        try {
            const rawMessage = event.data;
            if (typeof rawMessage !== 'string') {
                console.error('Received non-string message:', rawMessage);
                return;
            }

            // For task messages, use the task ID and action type for deduplication
            let messageId;
            let messageKey;
            if (rawMessage.startsWith('TASK_')) {
                const splitIndex = rawMessage.indexOf(':');
                const actionType = rawMessage.substring(0, splitIndex);
                const jsonStr = rawMessage.substring(splitIndex + 1);
                
                try {
                    const taskData = JSON.parse(jsonStr);
                    messageKey = `${actionType}-${taskData.id}`;
                    messageId = `${messageKey}-${taskData.lastUpdated}`;

                    // Check if we've seen this message recently (within 2 seconds)
                    const lastTimestamp = lastMessageTimestampRef.current[messageKey];
                    const now = Date.now();
                    if (lastTimestamp && now - lastTimestamp < 2000) {
                        console.debug('Skipping duplicate message (time-based):', messageId);
                        return;
                    }
                    lastMessageTimestampRef.current[messageKey] = now;
                } catch (e) {
                    messageId = rawMessage;
                }
            } else {
                messageId = rawMessage;
            }

            if (messageIdsRef.current.has(messageId)) {
                // Skip duplicate message
                console.debug('Skipping duplicate message (ID-based):', messageId);
                return;
            }
            messageIdsRef.current.add(messageId);

            // Handle special messages first
            if (rawMessage === 'connected') {
                console.log('✅ Server confirmed connection');
                reconnectAttemptRef.current = 0; // Reset reconnect attempts on successful connection
                return;
            }

            // Handle planning-related messages
            if (rawMessage === 'RESCHEDULING') {
                setIsRescheduling(true);
                enqueueSnackbar('Rescheduling tasks..', { variant: 'info', dense: true });
                return;
            } else if (rawMessage === 'RESCHEDULED') {
                setIsRescheduling(false);
                enqueueSnackbar('Rescheduled tasks', { variant: 'success', dense: true });
                queryClient.invalidateQueries('tasks');
                queryClient.invalidateQueries('calendarEvents');
                return;
            }

            // Handle customer-related messages
            if (rawMessage === 'CUSTOMER_UPDATED' || rawMessage === 'CUSTOMER_UPDATED_SUBSCRIPTION_RECEIPT') {
                refetchCustomer()
                    .then(() => {
                        setTimeout(() => {
                            queryClient.invalidateQueries('calendarEvents');
                        }, 0);
                    });
                enqueueSnackbar('Customer updated', { variant: 'success', dense: true });
                return;
            } else if (rawMessage === 'CUSTOMER_CALENDAR_ERROR') {
                enqueueSnackbar('Error fetching calendars, click here to reconnect your calendar.', {
                    variant: 'error',
                    dense: true,
                    action: (
                        <button onClick={() => navigate('/settings/account')} style={{ color: '#fff', background: 'none', border: 'none' }}>
                            Go to Settings
                        </button>
                    ),
                });
                return;
            }

            // Handle task-related messages
            if (rawMessage.startsWith('TASK_DELETED:')) {
                const taskId = rawMessage.substring(rawMessage.indexOf(':') + 1);
                enqueueSnackbar('Task Deleted', { variant: 'success', dense: true });
                queryClient.invalidateQueries('tasks');
            } else if (rawMessage.startsWith('TASK_UPDATED:') || rawMessage.startsWith('TASK_CREATED:')) {
                const splitIndex = rawMessage.indexOf(':');
                const actionType = rawMessage.substring(0, splitIndex);
                const jsonStr = rawMessage.substring(splitIndex + 1);
                
                try {
                    const taskData = JSON.parse(jsonStr);
                    console.log(`${actionType} received:`, taskData);
                    queryClient.invalidateQueries('tasks');
                    
                    if (actionType === 'TASK_CREATED') {
                        enqueueSnackbar('Task Created', { variant: 'success', dense: true });
                    } else {
                        enqueueSnackbar('Task Updated', { variant: 'success', dense: true });
                    }
                } catch (jsonError) {
                    console.error('Failed to parse task data:', jsonError);
                    console.log('Raw JSON string:', jsonStr);
                }
            } else if (rawMessage.startsWith('AI_RESPONSE:')) {
                const response = rawMessage.substring('AI_RESPONSE:'.length);
                console.log('AI response received:', response);
            }
            // Ignore all other messages (planning_requests, etc.)
        } catch (error) {
            console.error('Error handling message:', error);
            console.log('Raw message that caused error:', event.data);
        }
    }, [enqueueSnackbar, queryClient, setIsRescheduling, refetchCustomer, navigate]);

    const connect = useCallback(() => {
        if (isConnectingRef.current || !token || isTokenExpired) {
            return;
        }
    
        // Add check for existing connection
        if (socketRef.current?.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected, skipping connection');
            return;
        }
    
        isConnectingRef.current = true;
        console.log('🔌 Starting WebSocket connection...');
    
        try {
            // Properly handle HTTPS/WSS conversion
            const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            const wsUrl = new URL('/ws', import.meta.env.VITE_PUBLIC_API_HOST);
            wsUrl.protocol = wsProtocol;
            wsUrl.searchParams.append('token', token);
    
            console.log(`🔌 Connecting to WebSocket URL: ${wsUrl.toString()}`);
    
            const ws = new WebSocket(wsUrl.toString());
            
            // Track mount state
            const mountId = uuid();
            ws.mountId = mountId;
    
            // Add connection timeout
            const connectionTimeout = setTimeout(() => {
                if (ws.readyState !== WebSocket.OPEN) {
                    console.log('Connection timeout - closing socket');
                    ws.close();
                }
            }, 5000);
    
            ws.onopen = () => {
                // Only proceed if this is still the current mount
                if (ws.mountId !== mountId) {
                    console.log('Ignoring open event from old connection');
                    ws.close();
                    return;
                }
                
                clearTimeout(connectionTimeout);
                console.log('🟢 WebSocket Connected successfully');
                hasActiveConnectionRef.current = true;
                reconnectAttemptRef.current = 0;
                isConnectingRef.current = false;
                setSocket(ws);
            };
    
            ws.onmessage = handleMessage;
    
            ws.onclose = (event) => {
                clearTimeout(connectionTimeout);
                console.log('🔌 WebSocket closed:', event);
                hasActiveConnectionRef.current = false;
                isConnectingRef.current = false;
                if (event.code !== 1000) {  // Normal closure
                    handleReconnect();
                }
            };
    
            ws.onerror = (error) => {
                clearTimeout(connectionTimeout);
                console.log('❌ WebSocket error:', error);
                hasActiveConnectionRef.current = false;
                isConnectingRef.current = false;
            };
    
            socketRef.current = ws;
        } catch (error) {
            console.error('Error creating WebSocket:', error);
            isConnectingRef.current = false;
            handleReconnect();
        }
    }, [token, isTokenExpired, handleMessage, setSocket]);

    const handleReconnect = useCallback(() => {
        if (reconnectAttemptRef.current < MAX_RECONNECT_ATTEMPTS) {
            const delay = Math.min(RECONNECT_DELAY * Math.pow(2, reconnectAttemptRef.current), 10000);
            console.log(`🔄 Reconnecting in ${delay}ms... (attempt ${reconnectAttemptRef.current + 1}/${MAX_RECONNECT_ATTEMPTS})`);
            
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            
            reconnectTimeoutRef.current = setTimeout(() => {
                reconnectAttemptRef.current++;
                connect();
            }, delay);
        } else {
            console.log('❌ Max reconnection attempts reached');
            enqueueSnackbar('Connection lost. Please refresh the page.', {
                variant: 'error',
                persist: true,
                action: () => (
                    <button onClick={() => window.location.reload()}>
                        Refresh
                    </button>
                ),
            });
        }
    }, [connect, enqueueSnackbar]);

    useEffect(() => {
        if (token && !isTokenExpired && !hasActiveConnectionRef.current) {
            if (socketRef.current) {
                socketRef.current.close();
            }
            connect();
        }

        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [token, isTokenExpired, connect]);

    return null;
};

export default WebSocketConnector;