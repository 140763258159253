import { useCallback } from 'react';
import axios from 'axios';
import { subDays, addDays, startOfDay, endOfDay, differenceInHours, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';
import moment from 'moment-timezone';
import { logger } from '../../../utils/logger';

export const useMicrosoftCalendarEvents = (customer) => {
    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';
    const [token] = useAtom(tokenAtom);

    const fetchMicrosoftCalendars = useCallback(async (accessToken) => {
        try {
            logger.microsoft.info('Fetching Microsoft calendars');
            const { data } = await axios.get('https://graph.microsoft.com/v1.0/me/calendars', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const calendars = data.value.map((calendar) => ({
                id: calendar.id,
                summary: calendar.name,
                accessRole: 'owner',
                account: { type: 'microsoft', accessToken },
            }));
            logger.microsoft.debug(`Found ${calendars.length} Microsoft calendars`);
            return calendars;
        } catch (error) {
            if (error.response?.status === 401) {
                logger.microsoft.warn('Token expired - needs refresh');
                throw error; // Propagate 401 errors up
            }
            logger.microsoft.error('Failed to fetch Microsoft calendars:', error);
            return [];
        }
    }, []);

    const fetchMicrosoftEvents = useCallback(
        async (currentCalendarList) => {
            const defaultTimeZone = customer.defaultTimeZone;
            const now = new Date();
            const minDate = startOfDay(subDays(now, 7));
            const maxDate = endOfDay(addDays(now, 30));

            const microsoftCalendars = currentCalendarList.filter((calendar) => calendar.account.type === 'microsoft');

            if (!microsoftCalendars?.length) {
                logger.microsoft.info('No Microsoft calendars to fetch events from');
                return [];
            }

            logger.microsoft.info(`Fetching events from ${microsoftCalendars.length} Microsoft calendars`);
            const allEvents = await Promise.all(
                microsoftCalendars.map(async (calendar) => {
                    try {
                        const response = await axios.get(
                            `https://graph.microsoft.com/v1.0/me/calendars/${calendar.id}/calendarView`,
                            {
                                params: {
                                    startDateTime: formatInTimeZone(minDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ss"),
                                    endDateTime: formatInTimeZone(maxDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ss"),
                                    $select:
                                        'id,subject,start,end,showAs,isAllDay,type,recurrence,responseStatus,sensitivity,importance,categories,location,organizer,attendees,bodyPreview,onlineMeeting,webLink,isCancelled',
                                    $top: 1000,
                                },
                                headers: {
                                    Authorization: `Bearer ${calendar.account.accessToken}`,
                                    'Content-Type': 'application/json',
                                    'Prefer': 'IdType="ImmutableId"'
                                },
                            }
                        );

                        const events = response.data.value.map((event) => {
                            let start, end;

                            if (event.isAllDay) {
                                start = event.start.dateTime;
                                end = event.end.dateTime;
                            } else {
                                start = moment.tz(event.start.dateTime, event.start.timeZone).toDate();
                                end = moment.tz(event.end.dateTime, event.end.timeZone).toDate();
                            }

                            const isAllDay =
                                event.isAllDay || (differenceInHours(end, start) >= 24 && isSameDay(start, end));

                            return {
                                id: event.id,
                                calendarId: calendar.id,
                                htmlLink: event.webLink || '',
                                title: event.subject,
                                start: isAllDay ? start : start.toISOString(),
                                end: isAllDay ? end : end.toISOString(),
                                allDay: isAllDay,
                                color: '',
                                accessToken: calendar.account.accessToken,
                                showAs: event.showAs,
                                backgroundColor: event.showAs === 'free' || event.showAs === 'tentative' ? 'white' : '',
                                textColor: event.showAs === 'free' || event.showAs === 'tentative' ? 'black' : 'white',
                                borderColor: '#039be5',
                                serviceType: 'microsoft',
                                location: event.location?.displayName || '',
                                organizer: event.organizer?.emailAddress,
                                creator: event.organizer?.emailAddress,
                                attendees: event.attendees ? event.attendees.map(attendee => ({
                                    email: attendee.emailAddress.address,
                                    name: attendee.emailAddress.name,
                                    responseStatus: attendee.status.response
                                })) : [],
                                isOnlineMeeting: !!event.onlineMeeting,
                                isCancelled: event.isCancelled,
                                recurrence: event.recurrence,
                                importance: event.importance,
                                sensitivity: event.sensitivity,
                                categories: event.categories,
                                responseStatus: event.responseStatus,
                                type: event.type,
                                isMultiDay: event.isAllDay && !isSameDay(start, end),
                                multiDayEventEndDate: event.isAllDay ? end : null,
                            };
                        });
                        logger.microsoft.info(`Retrieved ${events.length} events from calendar ${calendar.id}`);
                        return events;
                    } catch (error) {
                        logger.microsoft.error(`Failed to fetch events for calendar ${calendar.id}:`, error);
                        return [];
                    }
                })
            );

            const flattenedEvents = allEvents.flat();
            logger.microsoft.info(`Microsoft Events: ${flattenedEvents.length} events from ${microsoftCalendars.length} calendars`);
            return flattenedEvents;
        },
        [customer]
    );

    const updateEventStatus = useCallback(async (eventId, calendarId, accessToken, newStatus) => {
        try {
            const response = await axios.patch(
                `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events/${eventId}`,
                {
                    showAs: newStatus
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const event = response.data;
            let start, end;

            if (event.isAllDay) {
                start = event.start.dateTime;
                end = event.end.dateTime;
            } else {
                start = moment.tz(event.start.dateTime, event.start.timeZone).toDate();
                end = moment.tz(event.end.dateTime, event.end.timeZone).toDate();
            }

            const isAllDay = event.isAllDay || (differenceInHours(end, start) >= 24 && isSameDay(start, end));

            return {
                id: event.id,
                calendarId: calendarId,
                htmlLink: event.webLink || '',
                title: event.subject,
                start: isAllDay ? start : start.toISOString(),
                end: isAllDay ? end : end.toISOString(),
                allDay: isAllDay,
                color: '',
                accessToken: accessToken,
                showAs: event.showAs,
                backgroundColor: event.showAs === 'free' || event.showAs === 'tentative' ? 'white' : '',
                textColor: event.showAs === 'free' || event.showAs === 'tentative' ? 'black' : 'white',
                borderColor: '#039be5',
                serviceType: 'microsoft',
                location: event.location?.displayName || '',
                organizer: event.organizer?.emailAddress,
                creator: event.organizer?.emailAddress,
                attendees: event.attendees ? event.attendees.map(attendee => ({
                    email: attendee.emailAddress.address,
                    name: attendee.emailAddress.name,
                    responseStatus: attendee.status.response
                })) : []
            };
        } catch (error) {
            logger.microsoft.error('Failed to update Microsoft event status:', error);
            throw error;
        }
    }, []);

    const handleMicrosoftAuth = useCallback(
        async (accessToken) => {
            logger.microsoft.info('Sending microsoftauth request to ' + baseUrl + 'oauth/microsoftauth');
            try {
                const response = await fetch(baseUrl + 'oauth/microsoftauth', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(accessToken),
                });

                const data = await response.json();
                return data;
            } catch (err) {
                logger.microsoft.error('Failed during Microsoft authentication:', err);
                return null;
            }
        },
        [baseUrl, token]
    );
    const connectMicrosoftAccount = useCallback(async () => {
        logger.microsoft.info('Connecting Microsoft account');
        try {
            const url = `${baseUrl}microsoft/connect`;
            const options = 'height=600,width=800';
            window.open(url, 'Microsoft Connect', options);
        } catch (err) {
            logger.microsoft.error('Failed to connect Microsoft account:', err);
        }
    }, [baseUrl]);

    const fetchMicrosoftCode = useCallback(
        async (code) => {
            try {
                const response = await fetch(baseUrl + 'microsoft/mscode', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ code }),
                });

                const answer = await response.json();
                return answer;
            } catch (err) {
                logger.microsoft.error('Failed to fetch Microsoft code:', err);
                return null;
            }
        },
        [baseUrl, token]
    );

    return {
        fetchMicrosoftCalendars,
        fetchMicrosoftEvents,
        updateEventStatus,
        handleMicrosoftAuth,
        connectMicrosoftAccount,
        fetchMicrosoftCode,
    };
};
