// React and React-related libraries
import React, { useCallback, useState, memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

// Third-party libraries
import { addDays, differenceInDays, isWithinInterval, startOfDay } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Capacitor } from '@capacitor/core';
import { ScheduleSend, CancelScheduleSend } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import ForwardIcon from '@mui/icons-material/Forward';
import { Tooltip } from '@mui/material';

// Local components
import TaskComponent from '../tasks/TaskComponent';
import { TaskScheduleStatus } from '../tasks/TaskScheduleStatus';

// Local utilities, hooks, and other functions
import { usePlanning } from '../../hooks/usePlanning';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { useTasks } from '../../providers/TasksProvider';
import { useTaskState } from '../tasks/hooks/useTaskState';
import { useTaskSchedule } from '../tasks/hooks/useTaskSchedule';
import { useDeviceType } from '../../hooks/useDeviceType';

// Local constants and types

// Styles and assets

const MemoizedFontAwesomeIcon = memo(FontAwesomeIcon);

export const CompletedIcon = memo(({ isCompleted, onComplete }) => {
    const iconColor = useMemo(() => (isCompleted ? 'green' : 'gray'), [isCompleted]);
    const memoizedOnClick = useCallback((e) => {
        if (onComplete) {
            onComplete(e);
        }
    }, [onComplete]);

    return (
        <MemoizedFontAwesomeIcon
            className="mr-1 text-3xl cursor-pointer pointer-events-auto"
            color={iconColor}
            data-bs-toggle="tooltip"
            title="Mark Task Complete"
            icon={faCheckCircle}
            onClick={memoizedOnClick}
        />
    );
});

CompletedIcon.propTypes = {
    isCompleted: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null, undefined])]),
    onComplete: PropTypes.func.isRequired,
};

const DayRowTask = memo(
    React.forwardRef(({ task, openPriorityPanel, isPlanner, isCalendarView, colorCode }, ref) => {
        // All hooks must be called before any conditional logic or returns
        const { isMobile } = useDeviceType();
        const { toggleScheduling: handleToggleScheduling, opportunityNowDate, overTheHorizonDate, parkingLotDate } = useTaskSchedule(task);
        const { openTaskModal } = useTaskModal();
        const { selectedTask, selectTask, saveTask } = useTasks();
        const { taskIsCurrent, updateTask } = useTaskState(task);

        // Move all useMemo and useCallback hooks here
        const priorityColorCode = useMemo(() => {
            switch (task.priority) {
                case 'CRITICAL':
                    return 'border-myncritical-400';
                case 'OPPORTUNITY_NOW':
                    return 'border-mynopportunitynow-400';
                case 'OVER_THE_HORIZON':
                    return 'border-mynoverthehorizon-400';
                case 'PARKING_LOT':
                    return 'border-mynparkinglot-400';
                default:
                    return 'border-red-400';
            }
        }, [task.priority]);

        const cleanColorCode = useMemo(() => 'text-' + priorityColorCode.replace('border-', ''), [priorityColorCode]);

        const memoizedOpenPriorityPanel = useCallback((e, taskArg) => {
            if (openPriorityPanel) {
                openPriorityPanel(e, taskArg);
            }
        }, [openPriorityPanel]);

        const memoizedNotifyDone = useCallback(() => {}, []);

        const handleClick = useCallback((e) => {
            e.stopPropagation();
            if (task.isRecurringChild) {
                selectTask(task.parentTask.id);
                openTaskModal(task.parentTask);
            } else {
                selectTask(task.id);
            }
        }, [task, selectTask, openTaskModal]);

        const onComplete = useCallback((e) => {
            e.stopPropagation();
            const taskElement = e.currentTarget.parentElement.querySelector('.text-ellipsis');
            if (taskElement) {
                taskElement.classList.toggle('strike');
            }
            
            const updatedTask = {
                ...task,
                isCompleted: !task.isCompleted,
                completedDate: !task.isCompleted ? new Date() : null,
                isAutoScheduled: task.isCompleted ? task.isAutoScheduled : false,
            };
            
            Promise.resolve().then(() => {
                updateTask(updatedTask);
                saveTask(updatedTask);
            });
        }, [task, updateTask, saveTask]);

        const toggleScheduling = useCallback((e) => {
            e.stopPropagation();
            const updatedTask = {
                ...task,
                schedulingState: !task.isAutoScheduled ? 'scheduling_requested' : 'scheduling_cancel_requested',
            };
            saveTask(updatedTask);
        }, [task, saveTask]);

        const handleKickToFuture = useCallback((e) => {
            e.stopPropagation();
            const futureDate = getFutureDate(task.priority);
            const updatedTask = {
                ...task,
                startDate: futureDate,
            };
            saveTask(updatedTask);
        }, [task, saveTask]);

        // Non-hook calculations after all hooks
        const isOnCalendar = task.calendarEvent?.startTime && task.calendarEvent?.endTime;
        const isTaskSelected = selectedTask?.id === task.id;

        // Regular functions after all hooks
        const getFutureDate = (priority) => {
            const tomorrow = addDays(new Date(), 1);

            switch (priority) {
                case 'CRITICAL':
                    return tomorrow;
                case 'OPPORTUNITY_NOW':
                    return opportunityNowDate;
                case 'OVER_THE_HORIZON':
                    return overTheHorizonDate;
                case 'PARKING_LOT':
                    return parkingLotDate;
                case 'TOMORROW':
                    return tomorrow;
                default:
                    console.error('Unrecognized task priority:', priority);
                    return null;
            }
        };

        const calculateDaysToFuture = () => {
            const futureDate = getFutureDate(task.priority);
            const today = startOfDay(new Date());
            const days = differenceInDays(futureDate, today);
            return `Move task ${days} days into the future`;
        };

        const handleTouchStart = (e) => {
            // setTouchStartY(e.touches[0].clientY);
        };

        const handleTouchEnd = (e) => {
            // if (touchStartY) {
            //     const touchEndY = e.changedTouches[0].clientY;
            //     const diff = touchEndY - touchStartY;
            //     if (Math.abs(diff) > 50) {
            //         handleKickToFuture(e);
            //     }
            // }
            // setTouchStartY(null);
        };

        return (
            <div 
                className="fc-event"
                ref={ref}
                data-task-id={task.id}
                data-title={task.title}
            >
                <div className="fc-event-main">
                    <div className="task-draggable">
                        <div className={`flex flex-row justify-between briefing`}>
                            {isTaskSelected ? (
                                <div className={!Capacitor.isNativePlatform() && !isMobile ? 'ml-9' : 'ml-2'}>
                                    <TaskComponent
                                        task={task}
                                        childTask={task}
                                        numberInList={0}
                                        key={task.id}
                                        notifyDone={memoizedNotifyDone}
                                        openPriorityPanel={(e) => memoizedOpenPriorityPanel(e, task)}
                                        onComplete={onComplete}
                                    />
                                </div>
                            ) : (
                                <button
                                    className={`flex items-center justify-between max-w-full hover:bg-gray-200 ${taskIsCurrent ? 'bg-green-100' : ''} ${isMobile ? 'px-1' : ''}`}
                                    style={{ width: '100%', textAlign: 'left' }}
                                    onClick={handleClick}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            handleClick(e);
                                        }
                                    }}
                                >
                                    {isPlanner && !isCalendarView && (
                                        <div className="drag-area" style={{ padding: '5px' }}>
                                            <div
                                                className={`flex items-center mr-3 justify-between ${cleanColorCode}`}
                                            >
                                                <MemoizedFontAwesomeIcon
                                                    className="text-2xl cursor-move"
                                                    icon={faGripVertical}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <CompletedIcon isCompleted={task.isCompleted} onComplete={onComplete} />
                                    <div className={`h-12 border-l-4 border-green-400 border-solid ${isMobile ? 'ml-0' : 'ml-2'} ${priorityColorCode}`}></div>
                                    <div className={`overflow-hidden flex-col ${isMobile ? 'ml-1' : 'ml-2'}`}>
                                        <div
                                            className={`text-black flex-1 ml-1 font-normal overflow-hidden text-lg truncate text-ellipsis font-sfCompactDisplay ${
                                                task.isCompleted ? 'strike' : ''
                                            }`}
                                        >
                                            {task.title}
                                        </div>

                                        <TaskScheduleStatus task={task} isOnCalendar={isOnCalendar} />
                                    </div>

                                    <div className="inline-flex justify-end items-center ml-auto h-12">
                                        {task.isCompleted === false || (task.isCompleted === null && !task.parentTask) ? (
                                            <ForwardIcon
                                                className={'mr-3 text-2xl cursor-pointer ' + cleanColorCode}
                                                titleAccess={calculateDaysToFuture()}
                                                onClick={handleKickToFuture}
                                            />
                                        ) : null}

                                        <div className="flex items-center">
                                            {!task.isCompleted && task.isLocked && (
                                                <Tooltip title="This task is locked">
                                                    <div className="mr-2">
                                                        <MemoizedFontAwesomeIcon
                                                            icon={faLock}
                                                            className="text-gray-500"
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {!task.isCompleted && (
                                                <Tooltip
                                                    title={
                                                        task.isAutoScheduled
                                                            ? 'Click to disable automatic scheduling for this task'
                                                            : 'Click to enable automatic scheduling for this task'
                                                    }
                                                    arrow
                                                    placement="top"
                                                    PopperProps={{
                                                        style: { pointerEvents: 'auto' },
                                                    }}
                                                >
                                                    <Chip
                                                        label={task.isAutoScheduled ? "Scheduled" : "Schedule"}
                                                        onClick={toggleScheduling}
                                                        size="small"
                                                        variant="outlined"
                                                        icon={
                                                            task.isAutoScheduled ? (
                                                                <CancelScheduleSend style={{ color: '#107CC4' }} />
                                                            ) : (
                                                                <ScheduleSend style={{ color: '#107CC4' }} />
                                                            )
                                                        }
                                                        sx={{
                                                            padding: '2px',
                                                            borderRadius: '9999px',
                                                            backgroundColor: 'white !important',
                                                            borderColor: task.isAutoScheduled ? '#107CC4' : '#107CC4',
                                                            marginRight: '-4px',
                                                            '&:hover': {
                                                                backgroundColor: 'white !important',
                                                                '& .MuiChip-label': {
                                                                    color: '#107CC4',
                                                                },
                                                                '& .MuiChip-icon': {
                                                                    color: '#107CC4',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {!task.isCompleted && (
                                                <MemoizedFontAwesomeIcon
                                                    id="ChangeTaskPriority"
                                                    className={
                                                        'ml-3 changeTaskPriorityClass text-3xl cursor-pointer hover:text-green-700 pointer-events-auto ' +
                                                        (isTaskSelected ? 'mr-0' : 'mr-1')
                                                    }
                                                    color={'orange'}
                                                    data-bs-toggle="tooltip"
                                                    title="Change priority"
                                                    icon={faExclamationTriangle}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        memoizedOpenPriorityPanel(e, task);
                                                    }}
                                                    onTouchEnd={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        memoizedOpenPriorityPanel(e, task);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

DayRowTask.propTypes = {
    task: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isCompleted: PropTypes.bool,
        parentTask: PropTypes.object,
        calendarEvent: PropTypes.shape({
            startTime: PropTypes.string,
            endTime: PropTypes.string,
        }),
        priority: PropTypes.string,
        title: PropTypes.string,
        isAutoScheduled: PropTypes.bool,
        startDate: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.func,
            PropTypes.any,
        ]),
    }).isRequired,
    openPriorityPanel: PropTypes.func.isRequired,
    isPlanner: PropTypes.bool.isRequired,
    isCalendarView: PropTypes.bool.isRequired,
};

export default DayRowTask;
