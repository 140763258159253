import React, { useCallback, memo, useState, useEffect } from 'react';
import { TextField, Switch, Grid, FormGroup, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Save from '@mui/icons-material/Save';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark as CloseTaskIcon, faTrashAlt, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import CustomDatePicker from './CustomDatePicker';
import TaskCompletedFieldset from './TaskCompletedFieldset';
import TaskScheduleSelector from './TaskScheduleSelector';
import TaskAddScheduleButton from './TaskAddScheduleButton';
import TaskSelectedSchedules from './TaskSelectedSchedules';
import RecurringEvent from './RecurringEvent';
import CalendarSelector from './CalendarSelector';
import { Task, Schedule, TaskPriority } from '../../domain/Task';
import { useCustomer } from '../../providers/CustomerProvider';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

const MemoizedFormControlLabel = memo(FormControlLabel);
const MemoizedFontAwesomeIcon = memo(FontAwesomeIcon);

interface TaskExpandedDetailsProps {
    task: Task;
    updateTask: (updates: Partial<Task>) => void;
    deleteTask: (id: string) => void;
    onComplete: (e: React.MouseEvent) => void;
    schedulesData: Schedule[];
    handleAddSchedule: (scheduleId: string) => void;
    handleDeleteSchedule: (id: string) => void;
    handleAutoScheduleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedColor: string;
    saveTask: (task: Task) => void;
    cancelEdit: (e: React.MouseEvent) => void;
    toggleSlidingWindow: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TaskExpandedDetails: React.FC<TaskExpandedDetailsProps> = ({
    task,
    updateTask,
    deleteTask,
    onComplete,
    schedulesData,
    handleAddSchedule,
    handleDeleteSchedule,
    handleAutoScheduleChange,
    selectedColor,
    saveTask,
    cancelEdit,
    toggleSlidingWindow,
}) => {
    const { customer } = useCustomer();
    const navigate = useNavigate();

    const handleNavigateToCalendar = useCallback((event: React.MouseEvent, date: string) => {
        event.preventDefault();
        event.stopPropagation();
        const formattedDate = format(parseISO(date), 'yyyy-MM-dd');
        window.location.href = `/calendar?date=${formattedDate}&view=timeGridDay`;
    }, []);

    const handleChange = useCallback(
        (field: keyof Task, value: any) => {
            updateTask((prevTask) => ({
                ...prevTask,
                [field]: value,
            }));
        },
        [updateTask]
    );

    const memoizedHandleChange = useCallback(
        (field: keyof Task) => (value: any) => handleChange(field, value),
        [handleChange]
    );
    const memoizedHandleAddSchedule = useCallback(
        () => handleAddSchedule(task.selectedSchedule || ''),
        [handleAddSchedule, task.selectedSchedule]
    );

    const handleSave = useCallback(() => {
        saveTask({ ...task });
    }, [saveTask, task]);

    const handleDelete = useCallback(() => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            deleteTask(task.id);
            cancelEdit(new Event('click') as React.MouseEvent);
        }
    }, [deleteTask, task.id, cancelEdit]);

    const memoizedSetRecurrenceRule = useCallback(
        (rule: string) => handleChange('recurrenceRule', rule),
        [handleChange]
    );

    const memoizedSetFrequency = useCallback(
        (frequency: string) => handleChange('selectedFrequency', frequency),
        [handleChange]
    );

    const getPriorityColor = (priority: TaskPriority): string => {
        switch (priority) {
            case 'CRITICAL':
                return '#0C803D';
            case 'OPPORTUNITY_NOW':
                return '#107CC4';
            case 'OVER_THE_HORIZON':
                return '#F9913B';
            case 'PARKING_LOT':
                return '#F04F23';
            default:
                return '#4CAF50';
        }
    };

    const [selectedCalendar, setSelectedCalendar] = useState(task.calendarId || '');

    const handleCalendarSelect = (calendarId) => {
        setSelectedCalendar(calendarId);
        updateTask({ calendarId });
    };

    if (!customer) {
        return null; // or some fallback UI
    }

    return (
        <div className="mt-4 bg-appBackground">
            <div className="flex flex-row mt-2">
                <div className="flex flex-grow mr-2">
                    <CustomDatePicker
                        label="Start Date"
                        value={task.startDate}
                        setValue={memoizedHandleChange('startDate')}
                        taskStartDate={task.startDate}
                        taskPriority={task.priority}
                        onPriorityChange={memoizedHandleChange('priority')}
                        renderButtons={true}
                        updateTask={updateTask}
                    />
                </div>
                <div className="flex flex-grow">
                    <CustomDatePicker
                        label="Deadline"
                        value={task.dueDate}
                        setValue={memoizedHandleChange('dueDate')}
                        clearable={true}
                        updateTask={updateTask}
                    />
                </div>
            </div>

            {task.isCompleted && <TaskCompletedFieldset task={task} onComplete={onComplete} />}

            <div className="flex flex-col pt-1 mt-3">
                <div className="mt-3">
                    {/* Lock and Auto-schedule row */}
                    <div className="flex items-center mb-4">
                        <div className="flex items-center mr-6">
                            <LockIcon className="mr-2 text-gray-500" sx={{ fontSize: '1.2rem' }} />
                            <Switch
                                checked={task.isLocked || false}
                                onChange={(e) => handleChange('isLocked', e.target.checked)}
                                size="small"
                            />
                            <span className="ml-2 text-sm text-gray-700">Task is {task.isLocked ? 'Locked' : 'Unlocked'}</span>
                        </div>

                        <div className="flex items-center">
                            <Switch
                                onChange={(e) => handleChange('isAutoScheduled', e.target.checked)}
                                checked={task.isAutoScheduled}
                                size="small"
                            />
                            <span className="ml-2 text-sm text-gray-700">Auto-schedule</span>
                        </div>
                    </div>

                    {/* Calendar Event Info */}
                    {task.calendarEvents && task.calendarEvents.length > 0 && (
                        <div className="mb-4">
                            <div 
                                onClick={(e) => handleNavigateToCalendar(e, task.calendarEvents[0].externalStartTime)}
                                className="flex items-center text-sm text-blue-600 hover:text-blue-800 cursor-pointer"
                            >
                                <FontAwesomeIcon icon={faCalendarDay} className="mr-2" />
                                <span className="uppercase font-medium">Scheduled for: </span>
                                <span className="ml-1">{format(parseISO(task.calendarEvents[0].externalStartTime), 'MMM d, yyyy h:mm a')}</span>
                            </div>
                        </div>
                    )}

                    {/* Calendar Selector */}
                    <div className="mb-6">
                        <CalendarSelector
                            accounts={customer.accounts}
                            selectedCalendar={selectedCalendar}
                            onCalendarSelect={handleCalendarSelect}
                        />
                    </div>

                    {/* Schedule Selection Area */}
                    <div className="space-y-4">
                        <div className="flex items-start">
                            <div className="flex-1">
                                <TaskScheduleSelector
                                    selectedSchedule={task.selectedSchedule || ''}
                                    setSelectedSchedule={memoizedHandleChange('selectedSchedule')}
                                    handleAddSchedule={handleAddSchedule}
                                    schedulesData={schedulesData}
                                />
                            </div>
                            <div className="ml-2 mt-1">
                                <TaskAddScheduleButton
                                    handleAddSchedule={memoizedHandleAddSchedule}
                                    selectedSchedule={task.selectedSchedule}
                                />
                            </div>
                        </div>

                        <div className="mt-2">
                            <TaskSelectedSchedules
                                selectedSchedules={task.schedules || []}
                                handleDeleteSchedule={handleDeleteSchedule}
                            />
                        </div>
                    </div>

                    {/* Sliding Window Option */}
                    {task.selectedFrequency !== null &&
                        task.isAutoScheduled &&
                        task.selectedFrequency !== undefined &&
                        !['Once', 'Daily', 'Weekdays', 'Weekly'].includes(task.selectedFrequency) && (
                            <div className="flex items-center mt-4">
                                <Checkbox
                                    checked={task.isSlidingWindow ?? true}
                                    onChange={(e) => {
                                        handleChange('isSlidingWindow', e.target.checked);
                                        toggleSlidingWindow(e);
                                    }}
                                    size="small"
                                />
                                <span className="ml-2 text-sm text-gray-700">Sliding Window</span>
                            </div>
                        )}
                </div>
            </div>
            <div>
                <RecurringEvent
                    setRecurrenceRule={memoizedSetRecurrenceRule}
                    recurrenceRule={task.recurrenceRule}
                    setFrequency={memoizedSetFrequency}
                    className="bg-appBackground"
                />
            </div>
            <div className="flex flex-grow mt-2 mb-3 w-full min-w-full max-w-max">
                <TextField
                    multiline
                    fullWidth
                    placeholder="Notes:"
                    size="large"
                    value={task.notes || ''}
                    onChange={(e) => handleChange('notes', e.target.value)}
                />
            </div>

            <div className="flex flex-row justify-between">
                <div className="flex">
                    <MemoizedFontAwesomeIcon
                        className="ml-3 text-4xl cursor-pointer hover:text-white hover:bg-mynlightblue-400 hover:opacity-100"
                        color={selectedColor}
                        icon={CloseTaskIcon}
                        onClick={cancelEdit}
                        data-bs-toggle="tooltip"
                        title="Cancel Edits"
                    />
                </div>
                <div className="flex items-center">
                    <MemoizedFontAwesomeIcon
                        className="mr-4 text-2xl text-red-600 cursor-pointer hover:text-red-800"
                        icon={faTrashAlt}
                        onClick={handleDelete}
                        data-bs-toggle="tooltip"
                        title="Delete Task"
                    />
                    <Button
                        endIcon={<Save />}
                        id="saveTaskButton"
                        onClick={handleSave}
                        variant="contained"
                        type="submit"
                        style={{ backgroundColor: getPriorityColor(task.priority) }}
                    >
                        Save Task
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(TaskExpandedDetails);
