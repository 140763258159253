import { openDB, IDBPDatabase } from 'idb';

const DB_NAME = 'mind-your-now-cache';
const DB_VERSION = 2;
const TASKS_STORE = 'tasks';
const CALENDAR_EVENTS_STORE = 'calendar-events';
const METADATA_STORE = 'metadata';

interface CacheMetadata {
    lastUpdated: number;
    hash: string;
}

interface CacheItem<T> {
    data: T;
    metadata: CacheMetadata;
}

let db: IDBPDatabase | null = null;

async function initializeDB(): Promise<IDBPDatabase> {
    if (db) return db;

    db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(database, oldVersion, newVersion, transaction) {
            if (oldVersion < 1) {
                database.createObjectStore(TASKS_STORE);
                database.createObjectStore(METADATA_STORE);
            }
            if (oldVersion < 2) {
                database.createObjectStore(CALENDAR_EVENTS_STORE);
            }
        },
        blocked() {
            console.warn('Please close other tabs using the application');
        },
        blocking() {
            db?.close();
            db = null;
        },
        terminated() {
            db = null;
        },
    });

    return db;
}

export function sanitizeForCache<T>(data: T): T {
    if (!data) return data;

    // Handle arrays
    if (Array.isArray(data)) {
        return data.map(item => sanitizeForCache(item)) as unknown as T;
    }

    // Handle objects
    if (typeof data === 'object') {
        const sanitized: any = {};
        for (const [key, value] of Object.entries(data)) {
            // Skip known circular reference fields
            if (['task', 'calendarEvent', 'googleEvent'].includes(key)) continue;
            
            // Special handling for calendar events
            if (key === 'calendarEvents' && Array.isArray(value)) {
                sanitized[key] = value.map(event => ({
                    id: event.id,
                    externalEventId: event.externalEventId,
                    externalStartTime: event.externalStartTime,
                    externalEndTime: event.externalEndTime,
                    externalTimeZone: event.externalTimeZone,
                    dateIdentifier: event.dateIdentifier
                }));
                continue;
            }

            // Skip functions and other non-serializable types
            if (typeof value === 'function') continue;
            if (value instanceof Date) {
                sanitized[key] = value.toISOString();
                continue;
            }

            sanitized[key] = sanitizeForCache(value);
        }
        return sanitized;
    }

    return data;
}

export async function generateHash(data: any): Promise<string> {
    const sanitizedData = sanitizeForCache(data);
    const msgUint8 = new TextEncoder().encode(JSON.stringify(sanitizedData));
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

async function putInStore<T>(store: string, key: string, value: T): Promise<void> {
    const database = await initializeDB();
    const tx = database.transaction(store, 'readwrite');
    await tx.objectStore(store).put(value, key);
    await tx.done;
}

async function getFromStore<T>(store: string, key: string): Promise<T | undefined> {
    const database = await initializeDB();
    const tx = database.transaction(store, 'readonly');
    const result = await tx.objectStore(store).get(key);
    await tx.done;
    return result;
}

export async function cacheData<T>(store: string, key: string, data: T): Promise<void> {
    try {
        // First, generate the hash and create metadata
        const hash = await generateHash(data);
        const metadata: CacheMetadata = {
            lastUpdated: Date.now(),
            hash
        };

        // Store data and metadata in separate transactions
        await putInStore(store, key, data);
        await putInStore(METADATA_STORE, `${store}-${key}`, metadata);
    } catch (error) {
        console.error(`Error caching data to ${store}:`, error);
        throw error;
    }
}

export async function getCachedData<T>(store: string, key: string): Promise<CacheItem<T> | null> {
    try {
        // Retrieve data and metadata in separate transactions
        const [data, metadata] = await Promise.all([
            getFromStore<T>(store, key),
            getFromStore<CacheMetadata>(METADATA_STORE, `${store}-${key}`)
        ]);

        if (!data || !metadata) {
            return null;
        }

        return { data, metadata };
    } catch (error) {
        console.error(`Error retrieving cached data from ${store}:`, error);
        return null;
    }
}

// Task-specific functions
export async function cacheTasks(tasks: any[]): Promise<void> {
    const sanitizedTasks = sanitizeForCache(tasks);
    return cacheData(TASKS_STORE, 'tasks', sanitizedTasks);
}

export async function getCachedTasks(): Promise<CacheItem<any[]> | null> {
    return getCachedData(TASKS_STORE, 'tasks');
}

// Calendar-specific functions
export async function cacheCalendarEvents(events: any[]): Promise<void> {
    const sanitizedEvents = sanitizeForCache(events);
    return cacheData(CALENDAR_EVENTS_STORE, 'events', sanitizedEvents);
}

export async function getCachedCalendarEvents(): Promise<CacheItem<any[]> | null> {
    return getCachedData(CALENDAR_EVENTS_STORE, 'events');
}

export async function clearCache(): Promise<void> {
    try {
        const database = await initializeDB();
        
        // Clear each store in a separate transaction
        await Promise.all([
            (async () => {
                const tx1 = database.transaction(TASKS_STORE, 'readwrite');
                await tx1.objectStore(TASKS_STORE).clear();
                await tx1.done;
            })(),
            (async () => {
                const tx2 = database.transaction(CALENDAR_EVENTS_STORE, 'readwrite');
                await tx2.objectStore(CALENDAR_EVENTS_STORE).clear();
                await tx2.done;
            })(),
            (async () => {
                const tx3 = database.transaction(METADATA_STORE, 'readwrite');
                await tx3.objectStore(METADATA_STORE).clear();
                await tx3.done;
            })()
        ]);
    } catch (error) {
        console.error('Error clearing cache:', error);
        throw error;
    }
}
