// src/domain/Task.ts

import { v4 as uuidv4 } from 'uuid';

export type TaskPriority = 'CRITICAL' | 'OPPORTUNITY_NOW' | 'OVER_THE_HORIZON' | 'PARKING_LOT';
export type TaskCategory = 'REGULAR' | 'PROJECT' | 'GOAL';

export interface Schedule {
    id: string;
}

export class Task {
    id: string;
    title: string;
    isCompleted: boolean;
    priority: TaskPriority;
    category: TaskCategory;
    ownerId: string;
    startDate: Date;
    dueDate: Date | null;
    createdDate: Date;
    lastUpdated: Date;
    completedDate: Date | null;
    notes: string | null;
    projectId: string | null;
    quantity: number | null;
    duration: number;
    isAutoScheduled: boolean;
    isLocked: boolean;
    allowSplitChunks: boolean;
    minChunkDuration: number;
    schedulingReason: string | null;
    schedulingState: string | null;
    recurrenceRule: string | null;
    recentRecurrenceCompletedDate: Date | null;
    schedules: Schedule[];
    isSlidingWindow: boolean;
    isRecurringParent: boolean;

    constructor(
        ownerId: string,
        title: string = '',
        priority: TaskPriority = 'OPPORTUNITY_NOW',
        category: TaskCategory = 'REGULAR',
        properties: Partial<Task> = {}
    ) {
        this.id = properties.id || uuidv4();
        this.title = title;
        this.isCompleted = properties.isCompleted || false;
        this.priority = priority;
        this.category = category;
        this.ownerId = ownerId;
        this.startDate = properties.startDate || new Date();
        this.dueDate = properties.dueDate || null;
        this.createdDate = properties.createdDate || new Date();
        this.lastUpdated = properties.lastUpdated || new Date();
        this.completedDate = properties.completedDate || null;
        this.notes = properties.notes || null;
        this.projectId = properties.projectId || null;
        this.quantity = properties.quantity || null;
        this.duration = properties.duration || -1;
        this.isAutoScheduled = properties.isAutoScheduled || false;
        this.isLocked = properties.isLocked || false;
        this.allowSplitChunks = properties.allowSplitChunks || false;
        this.minChunkDuration = properties.minChunkDuration || -1;
        this.schedulingReason = properties.schedulingReason || null;
        this.schedulingState = properties.schedulingState || null;
        this.recurrenceRule = properties.recurrenceRule || null;
        this.recentRecurrenceCompletedDate = properties.recentRecurrenceCompletedDate || null;
        this.schedules = properties.schedules || [];
        this.isSlidingWindow = properties.isSlidingWindow || false;
        this.isRecurringParent = properties.isRecurringParent || false;
    }

    update(updates: Partial<Task>): void {
        Object.assign(this, updates);
        this.lastUpdated = new Date();
    }
}
