// src/hooks/useTaskActions.js

import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';
import { v4 as uuidv4 } from 'uuid';

export const useTaskActions = (initialTask, taskHistory = null) => {
    const [token] = useAtom(tokenAtom);
    const queryClient = useQueryClient();
    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';

    const mapTaskToPOJO = useCallback((task) => {
        const taskPOJO = {
            id: task.id || uuidv4(),
            title: task.title || '',
            isCompleted: task.isCompleted || false,
            ownerId: task.ownerId,
            startDate: task.startDate ? new Date(task.startDate).toISOString() : new Date().toISOString(),
            dueDate: task.recurrenceRule && task.recurrenceRule.toUpperCase() !== 'ONCE' 
            ? null  // Clear due date for recurring tasks
            : (task.dueDate ? new Date(task.dueDate).toISOString() : null),
            createdDate: task.createdDate ? new Date(task.createdDate).toISOString() : null,
            lastUpdated: new Date().toISOString(),
            completedDate: task.completedDate ? new Date(task.completedDate).toISOString() : null,
            notes: task.notes || '',
            priority: task.priority || 'OPPORTUNITY_NOW',
            category: task.category || 'REGULAR',
            projectId: task.projectId || null,
            quantity: task.quantity || null,
            duration: task.duration !== undefined && task.duration !== '' ? task.duration : '-1',
            isAutoScheduled: task.isAutoScheduled || false,
            allowSplitChunks: task.allowSplitChunks || false,
            minChunkDuration: task.minChunkDuration || -1,
            schedulingReason: task.schedulingReason || null,
            schedulingState: task.schedulingState || null,
            recurrenceRule: task.recurrenceRule || null,
            recentRecurrenceCompletedDate: task.recentRecurrenceCompletedDate
                ? new Date(task.recentRecurrenceCompletedDate).toISOString()
                : null,
            isSlidingWindow: task.isSlidingWindow || false,
            schedules: task.schedules
                ? task.schedules.map((schedule) => (typeof schedule === 'object' ? schedule.id : schedule))
                : [],
            calendarId: task.calendarId || null,
            isLocked: task.isLocked || false,
        };

        return taskPOJO;
    }, []);

    const saveTaskMutation = useMutation(
        async (taskData) => {
            const isNewTask = !taskData.id || taskData.id === 'new';
            const url = isNewTask ? `${baseUrl}api/tasks/create` : `${baseUrl}api/tasks/${taskData.id}`;
            const method = isNewTask ? 'POST' : 'PUT';

            if (isNewTask) {
                taskData.id = uuidv4();
            }

            const response = await axios({
                method,
                url,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: taskData,
            });

            return response.data;
        },
        {
            onSuccess: (taskSchedule) => {
                queryClient.setQueryData(['tasks'], (oldTasks) => {
                    if (!oldTasks) return [taskSchedule.task];
                    const index = oldTasks.findIndex((t) => t.id === taskSchedule.task.id);
                    if (index === -1) {
                        return [...oldTasks, taskSchedule.task];
                    }
                    const updatedTasks = [...oldTasks];
                    updatedTasks[index] = taskSchedule.task;
                    return updatedTasks;
                });

                queryClient.setQueryData(['taskSchedule', taskSchedule.task.customerId], taskSchedule);

                queryClient.invalidateQueries('tasks');
                if (taskSchedule.task.isAutoScheduled) {
                    queryClient.invalidateQueries(['taskSchedule', taskSchedule.task.customerId]);
                }
            },
            onError: (error) => {
                console.error('Error saving task:', error);
            },
        }
    );
    const deleteTaskMutation = useMutation(
        async (taskId) => {
            const response = await axios.delete(`${baseUrl}api/tasks/${taskId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        },
        {
            onSuccess: (_, deletedTaskId) => {
                queryClient.setQueryData(['tasks'], (oldTasks) =>
                    oldTasks ? oldTasks.filter((t) => t.id !== deletedTaskId) : []
                );
            },
            onError: (error) => {
                console.error('Error deleting task:', error);
            },
        }
    );

    const saveTask = useCallback(
        async (updatedTask, description = 'Task update') => {
            const taskToSave = { ...initialTask, ...updatedTask };
            const taskPojo = mapTaskToPOJO(updatedTask);

            const savedTask = await saveTaskMutation.mutateAsync(taskPojo);

            if (taskHistory) {
                const currentTasks = queryClient.getQueryData(['tasks']) || [];
                taskHistory.pushState(currentTasks, description);
            }

            return savedTask;
        },
        [initialTask, mapTaskToPOJO, saveTaskMutation, queryClient, taskHistory]
    );

    const deleteTask = useCallback(
        async (taskId) => {
            if (taskId) {
                await deleteTaskMutation.mutateAsync(taskId);
            } else {
                console.error('Cannot delete task: No task ID available');
            }
        },
        [deleteTaskMutation]
    );

    const undoLastAction = useCallback(async () => {
        const previousState = taskHistory.undo();
        if (previousState) {
            // Update the tasks in the cache
            queryClient.setQueryData(['tasks'], previousState.tasks);

            // Sync with backend
            for (const task of previousState.tasks) {
                await saveTaskMutation.mutateAsync(mapTaskToPOJO(task));
            }

            // Invalidate queries to refresh UI
            queryClient.invalidateQueries('tasks');
        }
    }, [queryClient, saveTaskMutation, mapTaskToPOJO]);

    const canUndo = taskHistory?.canUndo() || false;

    return useMemo(
        () => ({
            saveTask,
            deleteTask,
            isSaving: saveTaskMutation.isLoading,
            isDeleting: deleteTaskMutation.isLoading,
            saveError: saveTaskMutation.error,
            deleteError: deleteTaskMutation.error,
            undoLastAction: taskHistory ? undoLastAction : undefined,
            canUndo,
        }),
        [
            saveTask,
            deleteTask,
            saveTaskMutation.isLoading,
            deleteTaskMutation.isLoading,
            saveTaskMutation.error,
            deleteTaskMutation.error,
            undoLastAction,
            canUndo,
        ]
    );
};
