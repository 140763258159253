import React, { useState, useMemo, useCallback, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    useTheme,
    useMediaQuery,
    AppBar,
    Toolbar,
    Box,
    Divider,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faFolder,
    faRecycle,
    faChartBar,
    faChartLine,
    faQuestionCircle,
    faCog,
    faCalendar,
    faCalendarWeek,
    faListCheck,
    faComments,
    faHandshake,
} from '@fortawesome/free-solid-svg-icons';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useSettings } from '../atoms/SettingsAtom';
import { Modal } from 'antd';
import { useCustomer } from '../providers/CustomerProvider';

const DRAWER_WIDTH = 240;
const COLLAPSED_WIDTH = 56;

const Logo = memo(() => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="/images/logo-no-text.png" alt="Logo" style={{ width: 32, height: 32, marginRight: 8 }} />
        <Link to="/home" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: '#00AEEF' }}>mind</span>
                <span style={{ color: '#80D7F7' }}>your</span>
                <span style={{ color: '#FFC60B' }}>now</span>
            </Box>
        </Link>
    </Box>
));

const MenuItem = memo(({ item, isMobile, setMobileOpen, isActive, isExpanded }) => (
    <ListItem
        key={item.path || item.label}
        component={item.path ? Link : 'div'}
        to={item.path}
        selected={isActive?.(item.path)}
        onClick={(e) => {
            if (isMobile && item.path) setMobileOpen(false);
            item.onClick?.(e);
        }}
        className={`nav-item ${isActive?.(item.path) ? 'active-nav-item' : ''}`}
        sx={{
            '&.active-nav-item': {
                backgroundColor: 'rgba(96, 165, 250, 0.1)',
                borderLeft: '4px solid #60A5FA',
            },
            '&.Mui-selected': {
                backgroundColor: 'rgba(96, 165, 250, 0.1) !important',
                '&:hover': {
                    backgroundColor: 'rgba(96, 165, 250, 0.2) !important',
                },
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
            justifyContent: isExpanded ? 'flex-start' : 'center',
            px: isExpanded ? 2 : 1,
            minHeight: 48,
        }}
    >
        <ListItemIcon sx={{ minWidth: isExpanded ? 40 : 'auto', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={item.icon} style={{ color: isActive?.(item.path) ? '#60A5FA' : '#9CA3AF' }} />
        </ListItemIcon>
        {isExpanded && (
            <ListItemText
                primary={item.label}
                sx={{
                    color: isActive?.(item.path) ? '#60A5FA' : 'inherit',
                    opacity: isExpanded ? 1 : 0,
                    transition: 'opacity 0.2s',
                }}
            />
        )}
    </ListItem>
));

const DrawerContent = memo(
    ({ menuItems, bottomMenuItems, isMobile, settings, updateSetting, setMobileOpen, isActive }) => (
        <Box sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            width: settings.sidebarExpanded ? `${DRAWER_WIDTH}px` : `${COLLAPSED_WIDTH}px`,
            transition: 'width 0.2s ease-in-out',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        }}>
            <Box sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: settings.sidebarExpanded ? 'space-between' : 'center',
                minHeight: 64,
                flexShrink: 0,
            }}>
                {settings.sidebarExpanded && <Logo />}
                {!isMobile && (
                    <IconButton
                        onClick={() => updateSetting('sidebarExpanded', !settings.sidebarExpanded)}
                        sx={{
                            minWidth: 40,
                            minHeight: 40,
                            padding: 1,
                        }}
                    >
                        {settings.sidebarExpanded ? (
                            <KeyboardArrowLeft sx={{ color: '#60A5FA' }} />
                        ) : (
                            <KeyboardArrowRight sx={{ color: '#60A5FA' }} />
                        )}
                    </IconButton>
                )}
            </Box>
            <Divider sx={{ flexShrink: 0 }} />
            <Box sx={{
                flex: 1,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: 'rgba(255, 255, 255, 0.2)',
                },
            }}>
                <List>
                    {menuItems.map((item) => (
                        <MenuItem
                            key={item.path}
                            item={item}
                            isMobile={isMobile}
                            isActive={isActive}
                            isExpanded={settings.sidebarExpanded}
                        />
                    ))}
                </List>
            </Box>
            <Divider sx={{ flexShrink: 0 }} />
            <List sx={{ flexShrink: 0 }}>
                {bottomMenuItems.map((item) => (
                    <MenuItem
                        key={item.path || item.label}
                        item={item}
                        isMobile={isMobile}
                        isActive={isActive}
                        isExpanded={settings.sidebarExpanded}
                    />
                ))}
            </List>
        </Box>
    )
);

const SideNavBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { customer, isLoading: isLoadingCustomer } = useCustomer();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const { settings, updateSetting } = useSettings();

    const menuItems = useMemo(
        () => [
            { path: '/home', icon: faHome, label: 'Home' },
            { path: '/calendar', icon: faCalendar, label: 'Calendar' },
            { path: '/upcoming-events', icon: faCalendarWeek, label: 'Upcoming Events' },
            { path: '/organizer', icon: faFolder, label: 'Organizer' },
            { path: '/habits', icon: faRecycle, label: 'Habits' },
            { path: '/planner', icon: faListCheck, label: 'Planner' },
            { path: '/stats', icon: faChartLine, label: 'Stats' },
        ],
        []
    );

    const bottomMenuItems = useMemo(
        () => [
            { path: '/affiliate', icon: faHandshake, label: 'Affiliate Program' },
            {
                icon: faComments,
                label: 'Roadmap',
                onClick: () => window.open('https://mindyournow.featurebase.app/roadmap', '_blank'),
            },
            {
                icon: faComments,
                label: 'Give Feedback',
                onClick: () => window.open('https://mindyournow.featurebase.app/?b=673cf41baac0a478e0b5e796', '_blank'),
            },
            {
                path: '/help',
                icon: faQuestionCircle,
                label: 'Help',
                onClick: () => window.open('https://www.mindyournow.com/docs/intro', '_blank'),
            },
            { path: '/settings', icon: faCog, label: 'Settings' },
        ],
        [setIsModalOpen]
    );

    const isActive = useCallback(
        (path) => {
            const currentPath = location.pathname;

            if (path === '/home' && (currentPath === '/' || currentPath === '/home')) {
                return true;
            }

            if (path === '/settings' && currentPath.startsWith('/settings')) {
                return true;
            }

            if (path === '/upcoming-events') {
                return currentPath === '/upcoming-events';
            }

            return currentPath === path;
        },
        [location.pathname]
    );

    const memoizedDrawerContent = useMemo(() => {
        if (!customer || isLoadingCustomer) return null;
        return (
            <DrawerContent
                menuItems={menuItems}
                bottomMenuItems={bottomMenuItems}
                isMobile={true}
                settings={{ ...settings, sidebarExpanded: true }}
                updateSetting={updateSetting}
                setMobileOpen={setMobileOpen}
                isActive={isActive}
            />
        );
    }, [customer, isLoadingCustomer, menuItems, bottomMenuItems, settings, updateSetting, setMobileOpen, isActive]);

    const memoizedSidebarContent = useMemo(() => {
        if (!customer || isLoadingCustomer) return null;
        return (
            <DrawerContent
                menuItems={menuItems}
                bottomMenuItems={bottomMenuItems}
                isMobile={false}
                settings={settings}
                updateSetting={updateSetting}
                setMobileOpen={setMobileOpen}
                isActive={isActive}
            />
        );
    }, [customer, isLoadingCustomer, menuItems, bottomMenuItems, settings, updateSetting, setMobileOpen, isActive]);

    const handleDrawerToggle = useCallback(() => {
        if (!customer || isLoadingCustomer) return;
        setMobileOpen(!mobileOpen);
    }, [customer, isLoadingCustomer, mobileOpen]);

    return (
        <>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: DRAWER_WIDTH,
                        backgroundColor: '#dbeafe',
                    },
                }}
            >
                {memoizedDrawerContent}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    flexShrink: 0,
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': {
                        width: settings.sidebarExpanded ? DRAWER_WIDTH : COLLAPSED_WIDTH,
                        backgroundColor: '#1a237e',
                        color: '#ffffff',
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        overflowX: 'hidden',
                        border: 'none',
                        height: '100vh',
                        position: 'relative'
                    }
                }}
            >
                {memoizedSidebarContent}
            </Drawer>
        </>
    );
};

export default SideNavBar;
