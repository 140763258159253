import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAtom } from 'jotai';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { isAuthenticatedAtom } from '../atoms/authAtoms';
import { tokenAtom, isTokenExpiredAtom } from '../atoms/tokenAtoms';
import { ErrorComponent } from '../components/ErrorComponent';
import CustomerLoadingScreen from '../components/CustomerLoadingScreen';
import GettingStarted from '../components/auth/GettingStarted';
import { WebSocketConnector } from '../hooks/useWebSocket';
import useNotifications from '../hooks/useNotifications';
import { CalendarEventsProvider } from '../contexts/CalendarEventsContext';

export const CustomerContext = createContext(null);

export const CustomerProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
    const [token, setToken] = useAtom(tokenAtom);
    const [isTokenExpired, setIsTokenExpired] = useAtom(isTokenExpiredAtom);
    const [clearedToken, setClearedToken] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const { notificationsRegistered, listenersAdded, pushToken } = useNotifications(isAuthenticated);

    const clearTokenAndAuthentication = useCallback(() => {
        setToken(null);
        setIsAuthenticated(false);
        setIsTokenExpired(true);
        setClearedToken(true);
    }, [setToken, setIsAuthenticated, setIsTokenExpired]);

    const {
        data: customer,
        error,
        refetch: refetchCustomer,
        isLoading,
    } = useQuery(
        ['customer', pushToken],
        async () => {
            console.log('👤 Fetching customer info...');
            if (!token) {
                console.log('🚫 No token available');
                return null;
            }
            try {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                const res = await axios({
                    method: 'post',
                    url: `${import.meta.env.VITE_PUBLIC_API_HOST}/api/getCustomerInfo`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    data: {
                        timeZone: timeZone,
                        registrationToken: pushToken,
                    },
                });
                console.log('API Response:', res);
                return res.data;
            } catch (error) {
                console.error('❌ Error fetching customer:', error);
                if (error.response?.status === 401) {
                    console.log('🔒 Token expired or invalid');
                    clearTokenAndAuthentication();
                }
                throw error;
            }
        },
        {
            enabled: !!token && isAuthenticated && !isTokenExpired,
            staleTime: 0, // Always consider data stale so we fetch fresh data
            cacheTime: 1000 * 60 * 60, // Keep in cache for 1 hour
            retry: 3,
            onError: (err) => console.error('Query Error:', err),
            onSuccess: (data) => {
                console.log('Customer:', data);
            },
        }
    );

    const contextValue = useMemo(
        () => ({
            customer,
            isLoading,
            error,
            refetchCustomer,
            notificationsRegistered,
            listenersAdded,
        }),
        [customer, isLoading, error, refetchCustomer, notificationsRegistered, listenersAdded]
    );

    if (isLoading) {
        console.log('Loading customer data...');
        return <CustomerLoadingScreen />;
    }

    if (clearedToken) {
        console.log('Token cleared, redirecting to GettingStarted...');
        return <GettingStarted />;
    }

    if (error) {
        console.error('Error loading customer data:', error);
        return <ErrorComponent message={error.message} retry={refetchCustomer} />;
    }

    return (
        <CustomerContext.Provider value={contextValue}>
            <CalendarEventsProvider>
                {children}
                {customer && <WebSocketConnector customer={customer} refetchCustomer={refetchCustomer} />}
            </CalendarEventsProvider>
        </CustomerContext.Provider>
    );
};

export const useCustomer = () => {
    const context = useContext(CustomerContext);
    if (context === undefined) {
        throw new Error('useCustomer must be used within a CustomerProvider');
    }
    return useMemo(() => context, [context]);
};
