import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useTasksHook } from '../components/tasks/hooks/useTasksHook';
import { useTaskActions } from '../components/tasks/hooks/useTaskActions';
import { useCalendarEvents } from '../contexts/CalendarEventsContext';
import { TaskHistory } from '../services/TaskHistory';
import { cacheTasks, getCachedTasks, sanitizeForCache } from '../services/CacheService';

const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
    const queryClient = useQueryClient();
    const [selectedFilter, setSelectedFilter] = useState('TODAYS_TASKS');
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [calendarEvents, setCalendarEvents] = useState([]);

    const {
        tasks: rawTasks,
        isLoading,
        error,
        refetch,
        getFilteredTasks,
        filteredTasks,
        filteredPrettyEvents,
        filteredPrettyEventsSorted,
        processedRecurringTasks,
    } = useTasksHook(true, selectedFilter, selectedCollection);

    const taskHistory = useMemo(() => new TaskHistory(), []);
    const { saveTask, deleteTask } = useTaskActions(null, taskHistory);

    const { calendarEvents: fetchedCalendarEvents, isLoading: isCalendarEventsLoading } = useCalendarEvents();

    const tasks = useMemo(() => {
        if (!rawTasks || !fetchedCalendarEvents || isCalendarEventsLoading) {
            return [];
        }

        const updatedTasks = [...rawTasks];
        const updatedEvents = [...fetchedCalendarEvents];

        // Create a map of event IDs to tasks for bidirectional linking
        const eventToTaskMap = new Map();

        updatedTasks.forEach((task) => {
            if (task.calendarEvents && task.calendarEvents.length > 0) {
                task.calendarEvents.forEach((taskCalendarEvent) => {
                    const matchedEvent = updatedEvents.find((event) => taskCalendarEvent.externalEventId === event.id);
                    if (matchedEvent) {
                        task.calendarEvent = matchedEvent;
                        task.googleEvent = {
                            start: matchedEvent.start,
                            end: matchedEvent.end,
                            googleStart: { dateTime: matchedEvent.start },
                            googleEnd: { dateTime: matchedEvent.end },
                        };
                        // Update the task's startDate to match the calendar event
                        task.startDate = matchedEvent.start;
                        task.endDate = matchedEvent.end;

                        // Store the task reference and calendar event ID on the event
                        matchedEvent.task = task;
                        matchedEvent.calendarEventId = taskCalendarEvent.externalEventId;
                        matchedEvent.calendarEvents = task.calendarEvents;
                        eventToTaskMap.set(matchedEvent.id, task);
                    }
                });
            }
        });

        // Ensure all events have their task references
        updatedEvents.forEach(event => {
            if (eventToTaskMap.has(event.id) && !event.task) {
                event.task = eventToTaskMap.get(event.id);
            }
        });

        return updatedTasks;
    }, [rawTasks, fetchedCalendarEvents, isCalendarEventsLoading]);

    async function generateHash(data) {
        const sanitizedData = sanitizeForCache(data);
        const msgUint8 = new TextEncoder().encode(JSON.stringify(sanitizedData));
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }

    useEffect(() => {
        const handleCaching = async () => {
            if (!rawTasks || isLoading) return;

            try {
                // Create a hash of the tasks for comparison using Web Crypto API
                const hash = await generateHash(rawTasks);

                // Check cache
                const cached = await getCachedTasks();
                if (cached?.metadata.hash === hash) {
                    console.log('Using cached tasks - no changes detected');
                    return;
                }

                // Cache has changed or doesn't exist, update it
                await cacheTasks(rawTasks);
                console.log('Tasks cache updated');
            } catch (error) {
                // Log error but don't throw - caching is non-critical
                console.warn('Failed to cache tasks:', error);
            }
        };

        handleCaching();
    }, [rawTasks, isLoading]);

    useEffect(() => {
        const loadFromCache = async () => {
            const cached = await getCachedTasks();
            if (cached && !rawTasks) {
                console.log('Loading tasks from cache');
                // TODO: Add a way to use cached tasks while waiting for fresh data
            }
        };

        loadFromCache().catch(console.error);
    }, []);

    const updateFilter = useCallback((newFilter) => {
        setSelectedFilter(newFilter);
        setSelectedCollection(null);
    }, []);

    const updateCollection = useCallback((newCollection) => {
        setSelectedCollection(newCollection);
        setSelectedFilter(null);
    }, []);

    const selectTask = useCallback((taskId) => {
        setSelectedTaskId(taskId);
    }, []);

    const deselectTask = useCallback(() => {
        setSelectedTaskId(null);
    }, []);

    const selectedTaskInfo = useMemo(() => {
        if (!selectedTaskId || !tasks || tasks.length === 0) {
            return { selectedTask: null, originalTask: null };
        }

        const selectedTask = tasks.find((task) => task.id === selectedTaskId);

        if (!selectedTask) {
            return { selectedTask: null, originalTask: null };
        }

        if (selectedTask.parentTask) {
            const parentTask = tasks.find((task) => task.id === selectedTask.parentTask.id);
            return {
                selectedTask: parentTask || selectedTask,
                originalTask: selectedTask,
            };
        }

        return {
            selectedTask,
            originalTask: selectedTask,
        };
    }, [selectedTaskId, tasks]);

    useEffect(() => {
        if (tasks) {
            taskHistory.pushState(tasks, 'Initial state');
        }
    }, [tasks]);

    const undoLastAction = useCallback(async () => {
        const previousState = taskHistory.undo();
        if (previousState) {
            queryClient.setQueryData(['tasks'], previousState.tasks);

            // Sync each task with backend
            for (const task of previousState.tasks) {
                await saveTask(task, 'Undo action');
            }
        }
    }, [taskHistory, queryClient, saveTask]);

    const canUndo = useMemo(() => taskHistory.canUndo(), [taskHistory]);

    const contextValue = useMemo(
        () => ({
            tasks,
            isLoading: isLoading || isCalendarEventsLoading || (!tasks.length && rawTasks?.length),
            error,
            refetch,
            getFilteredTasks,
            filteredTasks,
            filteredPrettyEvents,
            filteredPrettyEventsSorted,
            processedRecurringTasks,
            selectedFilter,
            selectedCollection,
            updateFilter,
            updateCollection,
            ...selectedTaskInfo,
            selectTask,
            deselectTask,
            saveTask,
            deleteTask,
            undoLastAction,
            canUndo,
        }),
        [
            tasks,
            isLoading,
            isCalendarEventsLoading,
            rawTasks,
            error,
            refetch,
            getFilteredTasks,
            filteredTasks,
            filteredPrettyEvents,
            filteredPrettyEventsSorted,
            processedRecurringTasks,
            selectedFilter,
            selectedCollection,
            updateFilter,
            updateCollection,
            selectedTaskInfo,
            selectTask,
            deselectTask,
            saveTask,
            deleteTask,
            undoLastAction,
            canUndo,
        ]
    );

    return <TasksContext.Provider value={contextValue}>{children}</TasksContext.Provider>;
};

export const useTasks = () => {
    const context = useContext(TasksContext);
    if (context === undefined) {
        throw new Error('useTasks must be used within a TasksProvider');
    }
    return context;
};
