import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useCustomer } from '../providers/CustomerProvider';
import { useGoogleCalendarEvents } from '../components/tasks/hooks/useGoogleCalendarEvents';
import { useMicrosoftCalendarEvents } from '../components/tasks/hooks/useMicrosoftCalendarEvents';
import { useAtom } from 'jotai';
import { tokenAtom } from '../atoms/tokenAtoms';
import { cacheCalendarEvents, getCachedCalendarEvents, clearCache } from '../services/CacheService';

const CalendarEventsContext = createContext();

export const useCalendarEvents = () => {
    const context = useContext(CalendarEventsContext);
    if (!context) {
        throw new Error('useCalendarEvents must be used within a CalendarEventsProvider');
    }
    return context;
};

export const CalendarEventsProvider = ({ children }) => {
    const { customer, isLoading: isCustomerLoading, refetchCustomer } = useCustomer();
    const [token] = useAtom(tokenAtom);
    const queryClient = useQueryClient();

    const { fetchGoogleCalendars, fetchGoogleEvents } = useGoogleCalendarEvents(customer);
    const { fetchMicrosoftCalendars, fetchMicrosoftEvents } = useMicrosoftCalendarEvents(customer);

    const refreshTokensAndUpdateCustomer = async () => {
        try {
            const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/refreshTokens`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('❌ Failed to refresh tokens:', response.statusText);
                throw new Error('Failed to refresh tokens');
            }

            const updatedCustomer = await response.json();
            console.log('🔄 Tokens refreshed successfully');
            // Update the customer data in the CustomerProvider
            await refetchCustomer();
            return updatedCustomer;
        } catch (error) {
            console.error('❌ Error refreshing tokens:', error);
            throw error;
        }
    };

    const fetchCalendarListForAllAccounts = useCallback(
        async (currentCustomer) => {
            console.log('📆 Fetching calendar list with customer:', {
                hasCustomer: !!currentCustomer,
                hasAccounts: !!currentCustomer?.accounts,
                numAccounts: currentCustomer?.accounts?.length,
                accounts: currentCustomer?.accounts?.map(acc => ({
                    id: acc.id,
                    type: acc.type,
                    error: acc.error,
                    dirty: acc.dirty,
                    hasToken: !!acc.accessToken
                }))
            });

            if (!currentCustomer || !currentCustomer.accounts || currentCustomer.accounts.length === 0) {
                console.warn('⚠️ No customer or accounts found');
                return [];
            }

            const newCalendarList = [];
            const accountsNeedingRefresh = [];

            for (const account of currentCustomer.accounts) {
                if (account.error || account.dirty) {
                    console.log(`🚫 Skipping account ${account.id} due to:`, {
                        error: account.error,
                        dirty: account.dirty
                    });
                    continue;
                }

                if (!account.accessToken) {
                    console.log(`🚫 Skipping account ${account.id} - no access token`);
                    continue;
                }

                try {
                    if (account.type === 'google') {
                        console.log(`📘 Fetching Google calendars for account ${account.id} with token:`, account.accessToken.substring(0, 10) + '...');
                        const accountCalendars = await fetchGoogleCalendars(account.accessToken);
                        if (accountCalendars?.items) {
                            console.log(`📆 Found ${accountCalendars.items.length} Google calendars`);
                            accountCalendars.items.forEach((calendar) => {
                                calendar.account = account;
                                newCalendarList.push(calendar);
                            });
                        } else {
                            console.warn(`⚠️ No Google calendars found for account ${account.id}`);
                        }
                    } else if (account.type === 'microsoft') {
                        console.log(`📘 Fetching Microsoft calendars for account ${account.id}`);
                        const accountCalendars = await fetchMicrosoftCalendars(account.accessToken);
                        if (accountCalendars && accountCalendars.length > 0) {
                            console.log(`💠 Found ${accountCalendars.length} Microsoft calendars`);
                            accountCalendars.forEach((calendar) => {
                                calendar.account = account;
                                newCalendarList.push(calendar);
                            });
                        } else {
                            console.warn(`⚠️ No Microsoft calendars found for account ${account.id}`);
                        }
                    }
                } catch (error) {
                    if (error.response?.status === 401) {
                        console.log(`🔄 Account ${account.id} needs token refresh due to 401 error`);
                        accountsNeedingRefresh.push(account);
                        continue;
                    }
                    console.error(`🚨 Error fetching calendars for account ${account.id}:`, error);
                    console.error('Error details:', {
                        message: error.message,
                        response: error.response?.data,
                        status: error.response?.status
                    });
                }
            }

            if (accountsNeedingRefresh.length > 0) {
                console.log('🔄 Some accounts need token refresh, refreshing tokens...', {
                    accounts: accountsNeedingRefresh.map(acc => acc.id)
                });
                const updatedCustomer = await refreshTokensAndUpdateCustomer();
                return fetchCalendarListForAllAccounts(updatedCustomer); // Retry with updated customer
            }

            if (currentCustomer && currentCustomer.accounts) {
                currentCustomer.accounts.forEach(account => {
                    const accountCalendars = newCalendarList.filter(cal => cal.account.id === account.id);
                    account.calendars = accountCalendars;
                });
            }

            console.log('📊 Calendar summary:', {
                total: newCalendarList.length,
                byType: newCalendarList.reduce((acc, cal) => {
                    acc[cal.account.type] = (acc[cal.account.type] || 0) + 1;
                    return acc;
                }, {})
            });

            return newCalendarList;
        },
        [fetchGoogleCalendars, fetchMicrosoftCalendars, refreshTokensAndUpdateCustomer]
    );

    const customerAccountsKey = useMemo(() => {
        return customer?.accounts?.map(acc => ({
            id: acc.id,
            type: acc.type,
            dirty: acc.dirty,
            error: acc.error
        })) || [];
    }, [customer]);

    const customerDeps = useMemo(
        () => ({
            accounts: customer?.accounts?.map((acc) => ({
                id: acc.id,
                type: acc.type,
                calendars: acc.calendars,
                accessToken: acc.accessToken,
                dirty: acc.dirty,
                error: acc.error,
            })),
            defaultTimeZone: customer?.defaultTimeZone,
        }),
        [customer]
    );

    const { data: calendarEvents, isLoading, error } = useQuery(
        ['calendarEvents', customer?.id, customerAccountsKey],
        async () => {
            // Clear the cache to force a refresh
            try {
                await clearCache();
                console.log('🧹 Cleared cache to force refresh');
            } catch (error) {
                console.warn('⚠️ Failed to clear cache:', error);
            }

            let events = null;
            
            // Try to load from cache first (should be empty now)
            try {
                const cached = await getCachedCalendarEvents();
                if (cached && cached.data && cached.data.length > 0) {
                    console.log('Using cached calendar events');
                    events = cached.data;
                } else {
                    console.log('Cache empty or invalid, fetching fresh calendar events');
                }
            } catch (error) {
                console.warn('Failed to load cached calendar events:', error);
            }

            // If no cache or cache is empty, fetch fresh data
            if (!events) {
                console.log('🔄 Fetching fresh calendar events...');
                events = await fetchCalendarEvents();
                console.log('✨ Fetched fresh events:', events?.length || 0);
                
                // Try to cache the fresh events
                if (events && events.length > 0) {
                    try {
                        await cacheCalendarEvents(events);
                        console.log('📦 Calendar events cache updated with', events.length, 'events');
                    } catch (error) {
                        console.warn('⚠️ Failed to cache calendar events:', error);
                    }
                } else {
                    console.warn('⚠️ No events fetched to cache');
                }
            }

            if (!events || events.length === 0) {
                console.warn('⚠️ No calendar events found!');
            }

            return events || [];
        },
        {
            enabled: !isCustomerLoading && !!customer,
            staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
            cacheTime: 30 * 60 * 1000, // Keep in react-query cache for 30 minutes
            retry: 2,
            onError: async (error) => {
                console.error('🚨 Calendar events query error:', error);
                if (error.message === 'Token expired') {
                    try {
                        await refreshTokensAndUpdateCustomer();
                        queryClient.invalidateQueries(['calendarEvents']);
                    } catch (refreshError) {
                        console.error('Failed to refresh tokens:', refreshError);
                    }
                }
            }
        }
    );

    const fetchCalendarEvents = useCallback(async () => {
        if (!customer || isCustomerLoading) {
            console.log('📆 Customer not loaded or loading');
            return [];
        }

        console.log('📆 Calendar fetch using customer:', {
            accounts: customer?.accounts?.map(acc => ({
                id: acc.id,
                type: acc.type,
                error: acc.error,
                dirty: acc.dirty,
                hasToken: !!acc.accessToken
            }))
        });

        try {
            // Add error boundary for calendar list fetching
            let calendarList;
            try {
                calendarList = await fetchCalendarListForAllAccounts(customer);
                console.log('🔍 Found ' + calendarList.length + ' calendars');
            } catch (error) {
                console.error('🚨 Error fetching calendar list:', error);
                return [];
            }

            let allEvents = [];

            // Add error boundaries for event fetching
            try {
                console.log('📅 Fetching calendar events...');

                const googleCalendars = calendarList.filter(cal => cal.account?.type === 'google');
                const microsoftCalendars = calendarList.filter(cal => cal.account?.type === 'microsoft');

                console.log(`📆 Found ${googleCalendars.length} Google calendars`);
                console.log(`💠 Found ${microsoftCalendars.length} Microsoft calendars`);

                if (googleCalendars.length > 0) {
                    const googleEvents = await fetchGoogleEvents({ items: googleCalendars });
                    allEvents.push(...googleEvents);
                    console.log('✨ Fetched Google events:', googleEvents.length);
                }

                if (microsoftCalendars.length > 0) {
                    const microsoftEvents = await fetchMicrosoftEvents(microsoftCalendars);
                    allEvents.push(...microsoftEvents);
                    console.log('✨ Fetched Microsoft events:', microsoftEvents.length);
                }

                console.log(`✨ Total events fetched: ${allEvents.length}`);
            } catch (error) {
                console.error('🚨 Error fetching calendar events:', error);
            }

            const processedEvents = allEvents.map((event) => ({
                ...event,
                isMultiDay: new Date(event.start).toDateString() !== new Date(event.end).toDateString(),
                multiDayEventEndDate: event.end,
                end:
                    new Date(event.start).toDateString() !== new Date(event.end).toDateString()
                        ? event.start
                        : event.end,
            }));

            console.log(`✨ Processed ${processedEvents.length} total events`);

            // Cache the processed events
            try {
                await cacheCalendarEvents(processedEvents);
                console.log('📦 Calendar events cached successfully');
            } catch (error) {
                console.warn('⚠️ Failed to cache calendar events:', error);
            }

            return processedEvents;
        } catch (error) {
            console.error('🚨 Error in fetchCalendarEvents:', error);
            return [];
        }
    }, [customer, isCustomerLoading, fetchCalendarListForAllAccounts, fetchGoogleEvents, fetchMicrosoftEvents]);

    const makeCalendarPrimary = useCallback(
        async (accountId) => {
            console.log('📆 Making ' + accountId + ' primary account');
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/makeAccountPrimary`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ accountId: accountId }),
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                await refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } catch (error) {
                console.error('🚨 Error making calendar primary:', error);
            }
        },
        [token, refetchCustomer, queryClient]
    );

    const deleteAccount = useCallback(
        async (email) => {
            console.log('📆 Deleting account ' + email);
            try {
                const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/deleteAccount`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                await refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } catch (error) {
                console.error('🚨 Error deleting account:', error);
            }
        },
        [token, refetchCustomer, queryClient]
    );

    const invalidateCalendarEvents = (source) => {
        queryClient.invalidateQueries({
            queryKey: 'calendarEvents',
            refetchType: 'active',
            meta: { source },
        });
    };

    const value = {
        calendarEvents,
        isLoading: isLoading || !calendarEvents,
        error,
        refetchCalendarEvents: useCallback(async () => {
            try {
                // Invalidate the query cache first
                queryClient.invalidateQueries({
                    queryKey: ['calendarEvents'],
                    refetchType: 'active',
                    meta: { source: 'calendarUpdate' }
                });

                // Then refetch the data
                const newCalendarList = await fetchCalendarListForAllAccounts(customer);
                const events = await Promise.all([
                    fetchGoogleEvents(newCalendarList),
                    fetchMicrosoftEvents(newCalendarList)
                ]);
                const allEvents = events.flat().filter(Boolean);
                return allEvents;
            } catch (error) {
                console.error('🚨 Error refetching calendar events:', error);
                throw error;
            }
        }, [customer, fetchCalendarListForAllAccounts, fetchGoogleEvents, fetchMicrosoftEvents, queryClient]),
        makeCalendarPrimary,
        deleteAccount,
    };

    return <CalendarEventsContext.Provider value={value}>{children}</CalendarEventsContext.Provider>;
};
