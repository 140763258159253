import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider, PaletteOptions } from '@mui/material/styles';

// Extend the PaletteOptions interface
declare module '@mui/material/styles' {
    interface Palette {
        PARKING_LOT: Palette['primary'];
        OPPORTUNITY_NOW: Palette['primary'];
        OVER_THE_HORIZON: Palette['primary'];
        CRITICAL: Palette['primary'];
    }
    interface PaletteOptions {
        PARKING_LOT?: PaletteOptions['primary'];
        OPPORTUNITY_NOW?: PaletteOptions['primary'];
        OVER_THE_HORIZON?: PaletteOptions['primary'];
        CRITICAL?: PaletteOptions['primary'];
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
        PARKING_LOT: {
            main: '#F04F23',
            contrastText: '#fff',
        },
        OPPORTUNITY_NOW: {
            main: '#107CC4',
            contrastText: '#fff',
        },
        OVER_THE_HORIZON: {
            main: '#F9913B',
            contrastText: '#fff',
        },
        CRITICAL: {
            main: '#0C803D',
            contrastText: '#fff',
        },
        dark: {
            main: '#000000',
            contrastText: '#ffffff',
        },
    },
});

import TaskDisplay from './TaskDisplay';
import TaskEdit from './TaskEdit';
import TaskExpandedDetails from './TaskExpandedDetails';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { useTaskActions } from './hooks/useTaskActions';
import { useTaskState } from './hooks/useTaskState';
import { useSchedules } from '../utils/scheduleApi';
import { useTasks } from '../../providers/TasksProvider';
import { Task } from '../../domain/Task'; // Import the Task type
import { TaskHistory } from '../../services/TaskHistory';

interface TaskComponentProps {
    task: Task; // Use the Task type here
    numberInList: number;
    notifyDone: () => void;
    openPriorityPanel: () => void;
    notifySaveAndClose: () => void;
    isModal: boolean;
    isPlanner: boolean;
    selectedFilter: string;
    selectedCollection: string;
}

// Wrap TaskEdit with React.memo
const MemoizedTaskEdit = React.memo(TaskEdit);

export const TaskComponent: React.FC<TaskComponentProps> = ({
    task: initialTask,
    numberInList,
    notifyDone,
    openPriorityPanel,
    notifySaveAndClose,
    isModal,
    isPlanner,
    selectedFilter,
    selectedCollection,
}) => {
    const taskHistory = useMemo(() => new TaskHistory(), []);
    const { task, updateTask } = useTaskState(initialTask);
    const { saveTask, deleteTask, isSaving, isDeleting, saveError, deleteError } = useTaskActions(
        initialTask,
        taskHistory
    );
    const { schedules, createSchedule, updateSchedule, deleteSchedule } = useSchedules();
    const { selectedTask, selectTask, deselectTask } = useTasks();
    const { closeTaskModal } = useTaskModal();

    const [autoScheduleValue, setAutoScheduleValue] = useState(task.isAutoScheduled);

    const [isSlidingWindow, setIsSlidingWindow] = useState(task.isSlidingWindow);

    const memoizedNotifyDone = useCallback(notifyDone, []);
    const memoizedOpenPriorityPanel = useCallback(openPriorityPanel, []);
    const toggleSlidingWindow = useCallback(() => {
        setIsSlidingWindow((prev) => !prev);
    }, []);

    const handleAddSchedule = useCallback(
        (scheduleId) => {
            console.log('Adding schedule:', scheduleId);
            const selected = schedules.find((schedule) => schedule.id === scheduleId);
            if (selected) {
                updateTask((prevTask) => {
                    const updatedSchedules = [...(prevTask.schedules || []), selected];
                    console.log('Updated schedules:', JSON.stringify(updatedSchedules, null, 2));
                    return { ...prevTask, schedules: updatedSchedules };
                });
            }
        },
        [schedules, updateTask]
    );

    const handleDeleteSchedule = useCallback(
        (id) => {
            updateTask((prevTask) => ({
                ...prevTask,
                schedules: prevTask.schedules.filter((schedule) => schedule.id !== id),
            }));
        },
        [updateTask]
    );

    const onComplete = useCallback(
        (e) => {
            e.stopPropagation();
            const updatedTask = {
                ...task,
                isCompleted: !task.isCompleted,
                completedDate: !task.isCompleted ? new Date() : null,
                isAutoScheduled: task.isCompleted ? task.isAutoScheduled : false,
            };
            updateTask(updatedTask);
            saveTask(updatedTask);
        },
        [task, updateTask, saveTask]
    );

    const handleSave = useCallback(
        async (updatedFields) => {
            console.log('Updated fields:', updatedFields);
            const updatedTask = { ...task, ...updatedFields };
            updateTask(updatedTask);
            await saveTask(updatedTask);
            deselectTask();
            closeTaskModal();
        },
        [task, updateTask, saveTask, deselectTask, closeTaskModal]
    );

    const selectThisTask = useCallback(
        (e) => {
            e.stopPropagation();
            console.log('Selecting task:', task.id);
            selectTask(task.id);
        },
        [task.id, selectTask]
    );

    const cancelEdit = useCallback(
        (e) => {
            e.stopPropagation();
            deselectTask();
            closeTaskModal();
        },
        [deselectTask, notifyDone, closeTaskModal]
    );

    const handleChangeDuration = useCallback(
        (e) => {
            const newDuration = e.target.value;
            updateTask((prevTask) => ({ ...prevTask, duration: newDuration }));
            console.log('Duration updated:', newDuration);
        },
        [updateTask]
    );

    const handleAutoScheduleChange = useCallback((e) => {
        setAutoScheduleValue(e.target.checked);
    }, []);

    const priorityColor =
        (task.priority == 'PARKING_LOT' || task.priority == 'OVER_THE_HORIZON') &&
        (new Date(task.startDate).getTime() < new Date().getTime() ||
            new Date(task.startDate).toDateString() === new Date().toDateString()) &&
        (task.isCompleted == null || task.isCompleted == 'false' || !task.isCompleted)
            ? 'red'
            : 'gray';

    let selectedColor;
    switch (task.priority) {
        case 'PARKING_LOT':
            selectedColor = '#F04F23';
            break;
        case 'OPPORTUNITY_NOW':
            selectedColor = '#107CC4';
            break;
        case 'OVER_THE_HORIZON':
            selectedColor = '#F9913B';
            break;
        case 'CRITICAL':
            selectedColor = '#0C803D';
            break;
    }

    let isSelectedTask = selectedTask?.id === initialTask.id;
    if (isModal) {
        isSelectedTask = true;
    }

    useEffect(() => {
        // Debug log to verify task data
        console.log('TaskComponent data:', {
            id: task.id,
            title: task.title,
            element: document.querySelector(`[data-task-id="${task.id}"]`)
        });
    }, [task]);

    return (
        <ThemeProvider theme={theme}>
            <div 
                className="fc-event"
                data-task-id={task.id}
                data-title={task.title}
            >
                <div className="fc-event-main">
                    <div className="task-draggable">
                        <div
                            className={`scrollable-dialog pr-0 ${
                                isSelectedTask && numberInList == 1 ? 'mt-[-10px]' : 'rounded-2xl'
                            }`}
                            key={task.key}
                        >
                            <div
                                id="TaskWrapper"
                                className={`flex flex-col m-0 mt-0 mb-4 bg-appBackground shadow-sky-400 max-w-1000 w-full 
                                    ${isSelectedTask ? 'p-2 rounded-xl border-4' : 'rounded-md'}`}
                                style={isSelectedTask ? { borderColor: selectedColor } : {}}
                            >
                                <div
                                    id="InnerTask"
                                    className={`flex-row flex-1 ${!isSelectedTask ? 'px-0 pt-2 pb-2' : 'mb-2'}`}
                                    onClick={selectThisTask}
                                >
                                    {isSelectedTask ? (
                                        <MemoizedTaskEdit
                                            task={task}
                                            updateTask={updateTask}
                                            saveTask={handleSave}
                                            deleteTask={deleteTask}
                                            onComplete={onComplete}
                                        />
                                    ) : (
                                        <TaskDisplay
                                            task={task.parentTask ?? task} // Use nullish coalescing operator
                                            onComplete={onComplete}
                                            openPriorityPanel={memoizedOpenPriorityPanel}
                                        />
                                    )}

                                    {task.startDate && !isSelectedTask && (
                                        <div id="secondrow" className="pb-0 mb-0 text-gray-500">
                                            {new Date(task.startDate).toLocaleDateString()}
                                        </div>
                                    )}
                                </div>
                                {isSelectedTask && (
                                    <TaskExpandedDetails
                                        task={task}
                                        updateTask={updateTask}
                                        deleteTask={deleteTask}
                                        onComplete={onComplete}
                                        handleChangeDuration={handleChangeDuration}
                                        schedulesData={schedules}
                                        handleAddSchedule={handleAddSchedule}
                                        handleDeleteSchedule={handleDeleteSchedule}
                                        handleAutoScheduleChange={handleAutoScheduleChange}
                                        selectedColor={selectedColor}
                                        saveTask={handleSave}
                                        cancelEdit={cancelEdit}
                                        toggleSlidingWindow={toggleSlidingWindow}
                                        theme={theme}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default TaskComponent;
