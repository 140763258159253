import { useCallback } from 'react';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';
import { useCustomer } from '../../../providers/CustomerProvider';
import { useQuery, useQueryClient } from 'react-query';
import { useTasks } from '../../../providers/TasksProvider';

const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';

export const useTaskSchedule = (task) => {
    const token = useAtomValue(tokenAtom);
    const { customer } = useCustomer();
    const queryClient = useQueryClient();
    const { saveTask, updateTask } = useTasks();

    const fetchTaskSchedule = useCallback(async () => {
        if (!token || !customer) throw new Error('Token or customer not available');

        const response = await axios.get(baseUrl + 'api/tasks/calculateTaskSchedule', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== 200) {
            throw new Error('Failed to fetch task schedule: ' + response.statusText);
        }

        return response.data;
    }, [token, customer]);

    const updateScheduleData = useCallback(
        (newScheduleData) => {
            queryClient.setQueryData(['taskSchedule', customer?.id], newScheduleData);
        },
        [queryClient, customer?.id]
    );

    const toggleScheduling = useCallback(async () => {
        try {
            const newAutoScheduled = !task.isAutoScheduled;
            
            // Update task in local state first for immediate feedback
            updateTask({
                isAutoScheduled: newAutoScheduled
            });

            // Then update in backend
            await saveTask({
                ...task,
                isAutoScheduled: newAutoScheduled
            });

            console.log(`Successfully ${newAutoScheduled ? 'enabled' : 'disabled'} auto-scheduling for task`);
        } catch (error) {
            console.error('Error toggling task scheduling:', error);
            // Revert local state on error
            updateTask({
                isAutoScheduled: !task.isAutoScheduled
            });
            throw error;
        }
    }, [task, updateTask, saveTask]);

    const {
        data: taskSchedule,
        isLoading,
        error,
    } = useQuery(['taskSchedule', customer?.id], fetchTaskSchedule, {
        enabled: !!token && !!customer,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
        onError: (error) => {
            console.error('Error fetching task schedule:', error);
        },
    });

    const invalidateTaskSchedule = useCallback(() => {
        queryClient.invalidateQueries(['taskSchedule', customer?.id]);
    }, [queryClient, customer?.id]);

    return {
        taskSchedule,
        opportunityNowDate: taskSchedule?.opportunityNowDate,
        overTheHorizonDate: taskSchedule?.overTheHorizonDate,
        parkingLotDate: taskSchedule?.parkingLotDate,
        isLoading,
        error,
        refetch: invalidateTaskSchedule,
        updateScheduleData,
        toggleScheduling,
    };
};

export default useTaskSchedule;
